import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import blinkscribelogo from '../../../assets/blinkscribe 4.svg';
import usericon from '../../../assets/user.png';
import keysymbol from '../../../assets/keysymbol.png';
import api from "../../../api/api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import ToastMessage from "utils/muialerts/righttoptoast";

const AuthOverlayCard = ({ isOpen, handleVerified }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showUploadSignature, setShowUploadSignature] = useState(false);
  const [uploadedSignature, setUploadedSignature] = useState(null);
 const [open, setOpen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('success');
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleSubmit = async () => {
    setErrorMessage('');
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match!');
      return;
    }
    if (!password.length) {
      setErrorMessage('Password is required!');
      return;
    }
   
    if (!username) {
      setErrorMessage('Username is required!');
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('username', username);
formData.append('new_password', password);
formData.append('confirm_password', confirmPassword);
if(uploadedSignature){
  formData.append('signature_file', uploadedSignature);
}

const response = await api.put('/v1/users/set-credentials', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

      if (response.status === 200) {
        sessionStorage.setItem('auth', response.access_token);
        handleVerified();
      }else{
        setSeverity('info')
        setAlertMessage(response?.message)
        setOpen(true)
      }
    } catch (error) {
      console.error(error);
      setSeverity('error')
      setAlertMessage('Something went Wrong ')
      setOpen(true)
    } finally {
      setLoading(false);
    } 
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadedSignature(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg']
    },
    multiple: false,
    onDrop,
    maxFiles: 1, // Accept only one file
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 sm:p-6 rounded-[30px] shadow-lg w-[90%] sm:w-[70%] sm:h-[800px] md:h-[600px] max-w-xl">
        <div className="flex flex-col justify-around h-full">
          <div className="flex justify-center mb-4">
            <img src={blinkscribelogo} alt="BlinkScribe Logo" className="h-16 w-auto sm:h-20" />
          </div>

          {!showUploadSignature ? (
            <>
              <div className="mb-4 text-center w-[50%] mx-auto">
                <div className="font-semibold text-[#1c2a3a] text-lg sm:text-xl leading-7">Set Username and Password</div>
                <div className="font-normal text-gray-500 text-xs sm:text-xs leading-[22.5px]">
                  Please create a username and password before moving forward.
                </div>
              </div>

              <div className="relative mb-4 w-[80%] mx-auto sm:w-[60%]">
                <input
                  type="text"
                  placeholder="Enter Username"
                  className="w-full bg-transparent h-[45px] border border-gray-300 p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <img src={usericon} alt="User Icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" />
              </div>

              <div className="relative mb-4 w-[80%] mx-auto sm:w-[60%]">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  className="w-full h-[45px] border border-gray-300 bg-transparent p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img src={keysymbol} alt="Key Symbol" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-3 flex items-center text-gray-600"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>

              <div className="relative mb-4 w-[80%] mx-auto sm:w-[60%]">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="w-full h-[45px] border border-gray-300 bg-transparent p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <img src={keysymbol} alt="Key Symbol" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" />
                <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  className="absolute inset-y-0 right-3 flex items-center text-gray-600"
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>

              <div 
                onClick={() =>{
                  setErrorMessage('');
            setUploadedSignature(null)
                setShowUploadSignature(true)}
                }
                className="font-semibold text-[#1c2a3a] text-[17px] cursor-pointer leading-7">
                <FontAwesomeIcon icon={faCloudArrowUp} /> Upload your Signature
              </div>
              {uploadedSignature && (
                <div className="font-normal text-center text-gray-500 text-xs sm:text-xs leading-[22.5px] mt-2">
                  File Name: {uploadedSignature.name}
                </div>
              )}

              {errorMessage && (
                <div className="text-red-500 text-xs sm:text-sm mb-4 w-[80%] mx-auto sm:w-[60%]">{errorMessage}</div>
              )}

              <div className="w-[80%] mt-2 mx-auto mb-4 sm:w-[60%]">
                <button
                  className={`w-full h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-full ${loading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? 'Updating' : 'Continue'}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="mb-4 text-center w-[50%] mx-auto">
                <div className="font-semibold text-[#1c2a3a] text-lg sm:text-xl leading-7">Upload Signature</div>
              </div>

              <div {...getRootProps()} className="flex flex-col items-center justify-center border border-dashed border-gray-400 h-[30%] p-4 text-center">
                <input {...getInputProps()} />
                <div>
                  <FontAwesomeIcon icon={faCloudArrowUp} />
                </div>
                <div className="font-normal text-gray-500 text-xs sm:text-xs leading-[22.5px]">
                  {isDragActive ? "Drop the file here..." : "Click or drag file to this area to upload your signature"}
                </div>
              </div>

              <div className="font-normal text-start text-gray-500 text-xs sm:text-xs leading-[22.5px]">
                Formats accepted are .png and .jpeg
              </div>

              {uploadedSignature && (
                <div className="font-normal text-start text-gray-500 text-xs sm:text-xs leading-[22.5px] mt-2">
                  File Name: {uploadedSignature.name}
                </div>
              )}

              <div className="w-[80%] mt-2 mx-auto mb-4 sm:w-[60%] flex justify-between">
                <button
                  onClick={() => {
                    setUploadedSignature(null)
                    setShowUploadSignature(false)}}
                  className="w-[48%] h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-full"
                >
                  Cancel
                </button>
                <button
  disabled={!uploadedSignature}  // Disable button if no signature is uploaded
  onClick={() => setShowUploadSignature(false)}
  className={`w-[48%] h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-full ${!uploadedSignature ? 'opacity-60 cursor-not-allowed' : ''}`}
>

                  Confirm
                </button>
              </div>
            </>
          )}
        </div>
      </div>
        <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
    </div>
  );
};

export default AuthOverlayCard;
