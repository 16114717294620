import { useState } from "react";

import { useDispatch } from "react-redux";
import { resetData, resetSettings, setProviders, setVisittype } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import AppOptions from "general/overlayscreens/appoptions";
import PricingOptions from "general/overlayscreens/pricing";
import MainOptions from "./mainoptions";
import SubOptions from "./suboptions";


const PlanOptionWrap=()=>{
    const [isPopupOpen, setPopupOpen] = useState(false);
    const dispatch= useDispatch()
    const navigate = useNavigate();
    const handleClosePopup = () => {
        dispatch(resetSettings())
        dispatch(setProviders([]))
        dispatch(setVisittype([]))
        dispatch(resetData());
        localStorage.removeItem('mytab')
        sessionStorage.removeItem('auth');
        navigate('/auth');

      setPopupOpen(false);
     
    };

    
const [screen,setScreen]=useState(0)
    return(<>
    {screen==0&&<MainOptions
 
    setScreen={setScreen}/>}
    {screen==1&&<SubOptions
       setScreen={setScreen}/>}

    </>)
    
}
export default PlanOptionWrap