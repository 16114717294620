import React, { useEffect, useState } from 'react';
import profilering from '../../assets/profilering.png';
import face from '../../assets/profile.png';
import { useNavigate } from 'react-router-dom';
import arrowleft from '../../assets/arrowleft.png';
import { Snackbar } from '@mui/material';
import Select from 'react-select';
import { Country, State } from 'country-state-city';
import api from 'api/api';

const EhrUpdate = ({ setScreen,setAlertMessage,setSeverity,setOpen,selectedEhr, username, email,  }) => {

  const[ehrpick,setPickEhr]=useState(selectedEhr||'')
    const handleSubmit=async()=>{
try {
       
    
            const response = await api.put('/v1/users/user-cofig-data', {
                "ehr_name": ehrpick,
                "user_type":"PRO"
            });
            if (response.status === 200) {
                setAlertMessage('Data updated successfully!')
              setSeverity('success')
              setOpen(true)
            } else {
                setAlertMessage('Error updating data:')
            setSeverity('error')
            setOpen(true)
            }
        } catch (error) {
            console.error("Error updating user config data:", error);
            setAlertMessage('Error updating user config data:')
            setSeverity('error')
            setOpen(true)
        } finally {
          
            setTimeout(() => {
                setScreen(0);      
            }, 1500);
        }


    }

   

    return (
        <div className="w-full bg-white sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-lg md:max-w-2xl p-6 shadow-lg rounded-lg h-screen sm:h-[400px] md:h-[400px]">
            <div className='flex gap-8'>
                <img onClick={() => setScreen(0)} src={arrowleft} alt="Back Arrow" className="w-5 h-5 cursor-pointer" />
                <div className="flex items-center gap-2">
                    <div className="relative">
                        <img src={profilering} alt="Profile Ring" className="w-[75px] h-[75px]" />
                        <img src={face} alt="Face" className="absolute w-[60px] h-[61px] left-2 top-3 rounded-full" />
                    </div>
                    <div className='flex flex-col text-start'>
                        <div className="font-semibold text-black text-lg">{username}</div>
                        <div className="opacity-50 text-black text-sm">{email || ''}</div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-around h-[80%]'>
                <label className="font-semibold text-black">Update EHR</label>
                <div className='w-full flex flex-col items-center gap-6 mt-4'>
                <div className="w-full md:w-3/5 sm:w-3/5 flex items-center space-x-2">
      <select
        className="block w-full text-sm   text-left p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={ehrpick}
        onChange={(e) => setPickEhr(e.target.value)}
      >
    <option value="" disabled>Select EHR (optional)</option>
    <option value="ATHENA">Athena</option>
    <option value="Epic">Epic</option>
    <option value="Cerner">Cerner</option>
 
  </select>
  
</div>
                </div>
                <div className='w-full flex justify-center mt-6'>
                    <button onClick={() => setScreen(0)} className="rounded-full w-[120px] bg-black text-white py-2 px-4 hover:bg-gray-800">Cancel</button>
                    <button onClick={handleSubmit} className="rounded-full w-[120px] bg-black text-white py-2 px-4 hover:bg-gray-800 ml-4">Save</button>
                </div>
            </div>
            
        </div>
    );
};

export default EhrUpdate;
