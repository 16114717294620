import RecordingWrap from "pages/recording/recordingwrap"
import BlinkscribeHome from "../pages/landing/BlinkScribeHome"

import LockPage from "pages/auth/screens/lock"
import UsernamePasswordLogin from "pages/auth/screens/logincred"
import OtpLoginScreen from "pages/auth/screens/login"
import BlinkScribeSignup from "pages/auth/screens/signup"
import Auth from "pages/auth/screens/auth"
// import Getotp from "pages/auth/screens/getotp"
import ForgotPassword from "pages/auth/screens/forgotpassword"
import Archive from "pages/recording/Archive"
import Editscreen from "pages/recording/Editscreen"
import Verify from "pages/auth/screens/Verify"
import UpdatePassword from "pages/auth/screens/updatepassword"
import ManageProf from "pages/profile/manageprof"
import Test from "test"
import BasicDashboard from "basic/dashboard/basicdashboard"
import TemplateBase from "pages/templatecreation/templatebase"
import EditTemplate from "pages/templatecreation/edittemplate"
import BasicArchive from "basic/archeive/basicarchive"
import BasicEditscreen from "basic/archeive/basiceditscreen"
import GeneralEditscreen from "general/archeive/generalarcheiveedit"
import OnBoarding from "pages/auth/onboarding"
import ModifyTemplate from "pages/templatecreation/modifytemplate"
import ManagePlans from "pages/profile/managePlans"
import Plans from "pages/plans/plans"

import ProManageTemplate from "pages/templatecreation/protemplate/promanagetemplate"
import TemplateEdit from "pages/templatecreation/templateedit"
import VoiceRecordingStream from "./socket"
import AccessNotes from "pages/auth/screens/acessnotes"
import ViewNote from "pages/auth/screens/viewnote"

import Soucket from "./soucket"
import PaymentStatus from "payments/paymentstatus"
import Meet from "./meet"
import Meeet from "./meet"


export const routes=[{
    path:'/landing',
    component:BlinkscribeHome,
    showHeader:true,
    allowedTypes: ["PRO"], 
},{
    path:'/record/:id/:patientid',
    component:RecordingWrap,
    showHeader:true,
    allowedTypes: ["PRO"], 
},
{
    path:'/archive/:id',
    component:Archive,
    showHeader:true,
    allowedTypes: ["PRO"], 
},
{
    path:'/archive-edit/:id/:name',
    component:Editscreen,
    showHeader:true,
    allowedTypes: ["PRO"],
},
{
    path:'/manage-profile',
    component:ManageProf,
    showHeader:true,
    allowedTypes: ["PRO","BASIC","PLUS",'BUSINESS'], 
},{
    path:'/auth',
    component:Auth,
    showHeader:false,
    allowedTypes: ["PRO","BASIC",'PLUS','BUSINESS'], 
},{
    path:'/test',
    component:Test,
    showHeader:false,
    allowedTypes: ["PRO","BASIC","PLUS",'BUSINESS'], 
},
{
    path:'/auth1',
    component:OtpLoginScreen,
    showHeader:false,
    allowedTypes: ["PRO","BASIC","PLUS",'BUSINESS'], 
},
{
    path:'/login',
    component:UsernamePasswordLogin,
    showHeader:false,
    allowedTypes: ["PRO","BASIC","PLUS",'BUSINESS'], 
},
{
    path:'/verify',
    component:Verify,
    showHeader:false,
    allowedTypes: ["PRO","BASIC","PLUS",'BUSINESS'], 
},
{
    path:'/forgotpassword',
    component:ForgotPassword,
    showHeader:false,
    allowedTypes: ["PRO","BASIC","PLUS",'BUSINESS'], 
},
{
    path:'/updatepassword',
    component:UpdatePassword,
    showHeader:false,
    allowedTypes: ["PRO","BASIC","PLUS",'BUSINESS'],  
},
{
    path:'/sign-up',
    component:BlinkScribeSignup,
    showHeader:false,
    allowedTypes: ["PRO","BASIC","PLUS",'BUSINESS'],  
}
,{
    path:'/lock',
    component:LockPage,
    showHeader:false,
    allowedTypes: ["PRO","BASIC",'PLUS','BUSINESS'],  
},
{
    path:'/onboarding',
    component:OnBoarding,
    showHeader:false,
    allowedTypes: ["PRO","BASIC",'PLUS','BUSINESS'],  
},
{
    path:'/manage-plans/:flow',
    component:ManagePlans,
    showHeader:false,
    allowedTypes: ["PRO","BASIC",'PLUS','BUSINESS'],  
},
{
    path:'/plans',
    component:Plans,
    showHeader:false,
    allowedTypes: ["PRO","BASIC",'PLUS','BUSINESS'],  
},

{
    path:'/doctor-instructions/:type/:token',
    component:AccessNotes,
    showHeader:false,
    allowedTypes: ["PRO","BASIC",'PLUS','BUSINESS'],  
},
{
path:'/view-note/:note',
component:ViewNote,
showHeader:false,
allowedTypes: ["PRO","BASIC",'PLUS','BUSINESS'],  
},
{
    path:'/soucket',
    component:Soucket,
    showHeader:false,
    allowedTypes: ["PRO","BASIC",'PLUS','BUSINESS'],  
    },
{
    path:'/temp',
    component:TemplateBase,
    showHeader:true,
    allowedTypes: ["PLUS",'BASIC'], 
},
{
    path:'/manage-template',
    component:ProManageTemplate,
    showHeader:true,
    allowedTypes: ['PRO'], 
},
{
    path: '/edit-temp/:name/:flow',
    component: EditTemplate,
    showHeader:true,
    allowedTypes: ["PLUS",'PRO']
}
,
{
    path: '/socket',
    component: VoiceRecordingStream,
    showHeader:true,
    allowedTypes: ["PLUS",'PRO']
},

{
    path: '/edit-template/:name/:flow',
    component: TemplateEdit,
    showHeader:true,
    allowedTypes: ["PLUS"]
}
,
{
    path: '/modify-template/:temp',
    component:ModifyTemplate,
    showHeader:true,
    allowedTypes: ["PLUS"]
}
,{
    path:'/app',
    component:BasicDashboard,
    showHeader:true,
    allowedTypes: ["BASIC",'PLUS','BUSINESS'], 
},
{
    path:'/basic-archive/:id',
    component:BasicArchive,
    showHeader:true,
    allowedTypes: ["BASIC",'PLUS','BUSINESS'], 
},
{
    path:'/basic-archive-edit/:id/:name',
    component:BasicEditscreen,
    showHeader:true,
    allowedTypes: ["BASIC","PLUS"],
},
{
    path:'/general-archive-edit/:id',
    component:GeneralEditscreen,
    showHeader:true,
    allowedTypes: ["BUSINESS"],
},
{
    path:'/payment/:status',
    component:PaymentStatus,
    showHeader:false,
    allowedTypes:["PRO","BASIC",'PLUS','BUSINESS'],
}
,
{
    path:'/meet',
    component:Meeet,
    showHeader:!false,
    allowedTypes:["PRO","BASIC",'PLUS','BUSINESS'],
}
]