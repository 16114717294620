import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../.././api/api';
import arrowleft from '../../assets/arrowleft.png';
import redmic from '../../assets/redmic.png';
import copydark from '../../assets/copy.svg';
import printdark from '../../assets/printicon.svg';
import MenuIcon from '@mui/icons-material/Menu';
import GridViewIcon from '@mui/icons-material/GridView';
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import Copyall from '../../assets/bi_copy.svg';
import { Backdrop, CircularProgress, Snackbar } from '@mui/material';
import ArchiveRecordingScreen from '../../pages/recording/archiveRecording';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSave, faUpRightFromSquare, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import ToastModal from 'alerts/recordingscreen/toastmodal';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ToastMessage from 'utils/muialerts/simplealert';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SplitscreenOutlinedIcon from '@mui/icons-material/SplitscreenOutlined';
import expandicon from '../../assets/expand.png'
import adveditpng from '../../assets/advediting.png'
import icroundmic from'../../assets/ic_round-mic.svg'
import { Expand, NoteTwoTone } from '@mui/icons-material';
import MicIcon from '@mui/icons-material/Mic';
import IndividualRecording from '../../pages/recording/indrecording';
import useModalStore from 'zustand/zustandstore';
import DashboardIcon from '@mui/icons-material/Dashboard';

import CloseIcon from '@mui/icons-material/Close';
import Share from 'basic/modals/share';
import ShareModal from 'basic/modals/share';
import ArcheiveStreaming from 'basic/archeivestreaming/archeiveStreaming';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import ExitModal from 'streaming/exitmodal';
import DeleteConfirmation from 'streaming/deleteconfirmation';
import useAudioRecorder from 'streaming/audiorecorderhook';
import ReactQuill from 'react-quill';
import { formatToText } from 'utils/jsonhandlers/jsontohtml';
import './quill.css'
const BasicEditscreen = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [indisRecording,setIndIsRecording]=useState(false)
    const [isbuttonloading,setIsButtonLoading]=useState(false)
  const[isdeletedaudio,setIsdelted]=useState(false)
  const [indisDeletedAudio,setIndIsDeletedAudio]=useState(false)
  const [streamfilename,setStreamfilename]=useState('')
   const [deleteconfirmationModal,setDeleteConfirmationModal]=useState(false)
  const [isExitModal,setIsexitModal]=useState(false)
  const [open, setOpen] = useState(false);
  const[jsonData,setJsonData]=useState([])
const [sectiondata,setSectionData]=useState({})
const[popup,setPopUp]=useState(false)
const [selectedCard,setSelectedCard]=useState('')
const [isSharemodal,setIsShareModal]=useState(false)
  const [summaryjson,setSummaryJson]=useState([])
  const [alertmessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [isError,setIsError]=useState(false)
  const[isLoading,setIsLoading]=useState(false)
  const mydata = useModalStore();
  const[config,setConfig]=useState({})
  const[audiofile,setAudiofile]=useState()
  const[toast,setToast]=useState(false)
  const [isExpanded,setIsexpanded]=useState(false)
  const [expandedRows, setExpandedRows] = useState({}); 
  const [rowSizes, setRowSizes] = useState({}); 
  const [data, setData] = useState({});
  const [view,setView]=useState(2)
  const excludedKeys = ["Date", "Patient_ID", "Patient_Name", "Visit_Summary", "Appointment_Summary"];
  const [recordingField,setRecordingField]=useState('')
  const [isIndRecord,setIsIndRecord]=useState(false) 
   const [elapsedTime, setElapsedTime] = useState(0);
   const [isPaused, setIsPaused] = useState(false);
   const recorderRef = useRef(null);
   const token = sessionStorage.getItem("auth");
   const audioStreamRef = useRef(null);
   const startTimeRef = useRef(null); // Tracks the timer's start/resume time
  const timerRef = useRef(null);
  const pausedref=useRef(false)
  const wsRef = useRef(null)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };
  
  const [fields, setFields] = useState({
    chiefcomplaint: '',
    hpi: '',
    ros: '',
    physicalexam: '',
    assessment: '',
    plan: ''
  });
  
  const [toastobject,setToastObject]=useState({
    icon:DangerousIcon,
    text1:"Something Went Wrong",
    text2:""

  })
  const percentage = 100;
  const circleStyle = {
    background: `conic-gradient(#50869A ${percentage * 3.6}deg, #EDEDED ${percentage * 3.6}deg)`,
  };
  const navigate = useNavigate();
  const { id ,name} = useParams();
  const blocker=useRef(false)
const[copyFeedback,setCopyFeedback]=useState('')
const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
}; 
const onSaving = (blob) => {
  if (blob && blob.type.startsWith("audio/")) {   
    console.log("Valid audio blob:", blob);
    // Call the function to submit the audio blob
    if(blocker.current){
      return
    }
    submitAudioBlobManually(blob);
  } else {
    console.error("Invalid blob. Not an audio file.");
  }

};
const {  manualrecord, stopmanualRecording, pausemanualRecording , resumemanualRecording } = useAudioRecorder(onSaving);
 const handlealertClick = (message, severity) => {
  setAlertMessage(message);
  setSeverity(severity);
  setOpen(true);
};
  const fetchData = async () => {
    setIsLoading(true)
    setFields({
      chiefcomplaint: '',
      hpi: '',
      ros: '',
      physicalexam: '',
      assessment: '',
      plan: ''
    })
    try {
         const response = await api.get(`/v1/recording/note/${id}/get`);
      if (response.status === 200) {
          const initialData =response.data[0]
        setData(initialData);
        const notesArray = Object.keys(initialData?.note_json||{})
        .filter(key => !excludedKeys.includes(key)) 
        .map((key, index) => ({
            id: index + 1,
            label: key.replace(/_/g, " "),
            key: key,
            title: key,
            value: initialData.note_json[key],
            placeholder: key,
        }));
 
        
        const order = [
          "chief_complaint",
          "physical_examination",
          "review_of_systems",
          "hpi",
          "assessment",
          "plan",
          "patient_directions",
          "allergies",
          "family_history",
          "medical_history",
          "social_history",
          "surgical_history",
        ];
        
        const sortedData = Array.isArray(notesArray)
          ? [
              ...order
                .map((key) =>
                  notesArray.find((item) => item.key?.toLowerCase() === key?.toLowerCase())
                )
                .filter(Boolean),
              ...notesArray.filter(
                (item) => !order.some((key) => key?.toLowerCase() === item.key?.toLowerCase())
              ),
            ]
          : [];
        
        
        // const sortedData = Array.isArray(notesArray)
        // ? notesArray.slice().sort((a, b) => a.key.localeCompare(b.key)) // Sort by key in alphabetical order
        // : [];
      
        // setSummaryJson(initialData?.summary_json||{})
        const transformKeysToLowercase = (obj) => {
          if (!obj || typeof obj !== 'object') return obj;
        
          return Object.keys(obj).reduce((acc, key) => {
            acc[key.toLowerCase()] = obj[key];
            return acc;
          }, {});
        };
        
        setSummaryJson(transformKeysToLowercase(initialData?.summary_json || {}));
        
setJsonData(sortedData||[])

        setConfig(response.data[0].config)
        setFields(initialData?.note_json)
      }
    } catch (error) {
      console.error('Error fetching data', error);
    }finally{
      setIsLoading(false)
    }
    
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  const submitAudioBlobManually=async(blob)=>{
    
    if(isdeletedaudio){
      setAudiofile('')
      handlealertClick('Recording has been Deleted','error')
    }else{
  
      try {
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("departmentid", config.departmentid);
        formData.append("category", "combine");
        formData.append("patientname", name);
        formData.append("patientid", config.patientid);
        // formData.append("appointmentid", config.appointmentid);
        if (config.appointmentid === null) {
          formData.append("appointmentid", null);
      } else {
          formData.append("appointmentid", config.appointmentid);
      }
      if(isIndRecord){
        formData.append("category",recordingField);
      }
      
        formData.append("notes", "testing data");
        formData.append("practiceid", config.practiceid);
        formData.append("filename", data.audiolink);
        formData.append("rowid", id);
        formData.append("tags", 'tagsdata');
     
        if(mydata.usertype=='PLUS'){
          // formData.append('template_name',config.template_name);
          if (config.template_name === null) {
            formData.append('template_name', null);
        } else {
            formData.append('template_name', config.template_name);
        }
         }
        const response = await api.post('v1/ehr/recording/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      
        if (response.status == 200) {
          handlealertClick('Recording Has Been Uploaded');
         
       
        } else {
          handlealertClick('Something Went Wrong', 'error');
        }
        
      } catch (error) {
        console.error('Error uploading audio:', error);
      }finally{
        setIsError(false)
        setIsexitModal(false)
      }
      
      }
    
  
  }
  const submitdata = async (payload, text,fieldname) => {
    const a = fieldname;
    const b = text;
    const obj = { [a]: b };
    const transformedData = {
      "context_dict": obj 
    };
    setIsLoading(true); 
   
    try {
      const response = await api.put('/v1/recording/category/update',{
            ...transformedData,
        file_name:data.audiolink
      
      });
      fetchData()
      // Handle response here if needed
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
   
  };
  const handleSignoff=async()=>{
    
    if(isbuttonloading)return
    setIsButtonLoading(true)
    try {
      const response= await api.put('/v1/document/sign-off',{"document_name":data?.audiolink})
      if(response.status===200){
       handlealertClick('Signed!');
      
        setOpen(true);
      }else if(response.status===201){
              handlealertClick(response.message)
        setOpen(true);    
      }
    } catch (error) {
      console.log(error)
      handlealertClick(('something went wrong','error'))
  
      setOpen(true);  
    }finally{
      setIsButtonLoading(false)
      fetchData()
    }
    setTimeout(() => {
      navigate('/app', { replace: true }); // This will redirect to /app
    }, 1000);
  }
  
  const copyToClipboard = (htmlText) => {
    // Create a temporary div to parse HTML
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlText;
  
    // Extract plain text without HTML tags
    const plainText = tempElement.innerText || tempElement.textContent;

    // Copy to clipboard
    navigator.clipboard.writeText(plainText)
      .then(() => {
        handlealertClick("Copied to clipboard!", "success");
      })
      .catch(err => {
        console.error("Failed to copy text: ", err);
      });
  };
  


  const handleFieldChange = (fieldName, value) => {
    setFields(prevFields => ({ ...prevFields, [fieldName]: value }));
  };

  const handleSaveIconClick = (fieldName) => {
   const fieldValue = fields[fieldName]; 
    const dataToSubmit = {
      [fieldName]: fieldValue,
      category: fieldName
    };
    submitdata(dataToSubmit,fieldValue,fieldName);  
    // Log the details to the console
  setPopUp(false)
  };
  

  const handleSaveButtonClick = async() => {
    if (data.transcriptionstatus === 'Processing') {
      setAlertMessage('Update in process. Hold on!');
      setSeverity('info'); // Change severity as needed
      setOpen(true);
      return;
    }

       const changedFields = {};
    for (const key in fields) {
      if (fields[key] !== data[key]) {
        changedFields[key] = fields[key];
      }
    }  
    const transformedData = {
      context_dict: changedFields // Assigning changedFields directly
    };
    setIsLoading(true); 
   
    if( Object.keys(transformedData.context_dict).length === 0){
      setIsLoading(false)
      handlealertClick('No Updated Data Found','error')
      
      return
    }
    try {

      const response = await api.put('/v1/recording/category/update',{
            ...transformedData,
        file_name:data.audiolink
      
      });

     
      fetchData()
      // Handle response here if needed
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  
  };
  const submitIndAudio= async (blob)=>{
    if(indisDeletedAudio){
      setAudiofile('')
      handlealertClick('Recording has been Deleted','error')
      setIsIndRecord(false)
      setRecordingField('')
      setIndIsDeletedAudio(false)
    }else{
 setIsIndRecord(false)

try {
  const formData = new FormData();
  formData.append("file", blob);
  formData.append("departmentid", config.departmentid);
  formData.append("category", recordingField);
  formData.append("patientname", name);
  formData.append("patientid", config.patientid);
  formData.append("notes", "testing data");
  formData.append("practiceid", config.practiceid);
  formData.append("filename", data.audiolink);
  formData.append("rowid", id);
  formData.append("tags", 'tagsdata');
 if(mydata.usertype=='PLUS'){
  // formData.append('template_name',config.template_name);
  if (config.template_name === null) {
    formData.append('template_name', null);
} else {
    formData.append('template_name', config.template_name);
}
 }
  
  const response = await api.post('v1/ehr/recording/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status == 200) {
    handlealertClick('Recording Has Been Uploaded');
    navigate(-1)

  } else {
    handlealertClick('Something Went Wrong', 'error');
  }
  
} catch (error) {
  console.error('Error uploading audio:', error);
}finally{
  setRecordingField('')
}
    }
  }
const submitAudio= async (blob)=>{
  if(isdeletedaudio){
    setAudiofile('')
    handlealertClick('Recording has been Deleted','error')
  }else{

    try {
      const formData = new FormData();
      formData.append("file", blob);
      formData.append("departmentid", config.departmentid);
      formData.append("category", "combine");
      formData.append("patientname", name);
      formData.append("patientid", config.patientid);
      // formData.append("appointmentid", config.appointmentid);
      if (config.appointmentid === null) {
        formData.append("appointmentid", null);
    } else {
        formData.append("appointmentid", config.appointmentid);
    }
    
      formData.append("notes", "testing data");
      formData.append("practiceid", config.practiceid);
      formData.append("filename", data.audiolink);
      formData.append("rowid", id);
      formData.append("tags", 'tagsdata');
   
      if(mydata.usertype=='PLUS'){
        // formData.append('template_name',config.template_name);
        if (config.template_name === null) {
          formData.append('template_name', null);
      } else {
          formData.append('template_name', config.template_name);
      }
       }
      const response = await api.post('v1/ehr/recording/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    
      if (response.status == 200) {
        handlealertClick('Recording Has Been Uploaded');
        navigate(-1)
     
      } else {
        handlealertClick('Something Went Wrong', 'error');
      }
      
    } catch (error) {
      console.error('Error uploading audio:', error);
    }
    
    }
}
const handleCopyallText = () => {
  let textToCopy = '';

  for (const key in fields) {
    if (fields.hasOwnProperty(key)) {
      let value = fields[key];

      if (Array.isArray(value)) {
        // Format array values as a list
        value = value.map(item => `- ${item}`).join('\n');
      } else if (typeof value === 'object' && value !== null) {
        // Format object properties without JSON symbols
        let formattedObject = '';
        for (const subKey in value) {
          if (value.hasOwnProperty(subKey)) {
            formattedObject += `${subKey}: ${value[subKey]}\n`;
          }
        }
        value = formattedObject.trim();
      } else {
        // Strip HTML if value is a string
        const tempElement = document.createElement("div");
        tempElement.innerHTML = value;
        value = tempElement.innerText || tempElement.textContent;
      }

      textToCopy += `${key}: \n${value}\n\n`;
    }
  }



  navigator.clipboard.writeText(textToCopy)
    .then(() => {
      handlealertClick('Copied to Clipboard', 'success');
    })
    .catch(err => {
      console.error('Failed to copy text: ', err);
      handlealertClick('Something went wrong', 'error');
    });
};




const startRecording = async (category='combine') => {
  pausedref.current = false;
  try {
    const text = crypto.randomUUID();
    setStreamfilename(text)
    const filename = data.audiolink;
    const practiceid = config.patientid || "";
    const departmentid = config.departmentid || "";
    const templatename=config.template_name||null
    const appointmentid = config.appointmentid
    const tags = "Sample tags";
    const patientname = name;
    const isBusinessUser = mydata.usertype=='BUSINESS';
    const patientid = config.patientid
    const notes ="testing data"
 
  const ws = new WebSocket(
  `wss://api.prd.brainymed.com/bs/v1/recording/streaming?token=${token}&rowid=${id}&stream_filename=${text}&practiceid=${practiceid}&departmentid=${departmentid}&patientid=${patientid}&appointmentid=${appointmentid}&notes=${encodeURIComponent(
    notes
  )}&tags=${encodeURIComponent(tags)}&filename=${filename}&category=${category}&patientname=${encodeURIComponent(patientname)}&template_name=${encodeURIComponent(templatename)}`
  
);

    wsRef.current = ws;

    ws.onopen = () => {
    
      console.log("WebSocket connection established.");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      setIsError(true)
      manualrecord()
  
    };

    ws.onclose = () => {
  
      console.log("WebSocket connection closed.");
    };

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    audioStreamRef.current = stream;

    const recorder = new RecordRTC(stream, {
      type: "audio",
      recorderType: StereoAudioRecorder,
      mimeType: "audio/webm",
      timeSlice:5000, // Send data every 5 seconds
      desiredSampRate: 48000,
      numberOfAudioChannels: 1,
      ondataavailable: async (blob) => {
     
        if (ws.readyState === WebSocket.OPEN && !pausedref.current) {
          try {
          
            const base64Data = await blobToBase64(blob);
            ws.send(base64Data);
          } catch (error) {
            console.error("Error converting blob to base64:", error);
          }
        }
      },
    });

    recorder.startRecording();
    recorderRef.current = recorder;

    setElapsedTime(0);
    startTimer();
  
    setIsPaused(false);
  } catch (error) {
    console.error("Error starting recording:", error);
   
  }
};
const startTimer = () => {
  startTimeRef.current = Date.now() - elapsedTime * 1000; // Adjust for any previously elapsed time
  timerRef.current = setInterval(() => {
    const timePassed = Math.floor((Date.now() - startTimeRef.current) / 1000);
    setElapsedTime(timePassed);
  }, 1000);
};

const stopTimer = () => {
  clearInterval(timerRef.current);
  timerRef.current = null;
};

const togglePause = () => {

  if (pausedref.current) {
    // Resume the timer
    pausedref.current=false
  setIsPaused(false)
    startTimer();
    if(isError){
      resumemanualRecording()
    }
  } else {
    // Pause the timer
    if(isError){
      pausemanualRecording()
   }
    pausedref.current=true
   setIsPaused(true)
    stopTimer();
  }

};
const stopRecording = () => {
  if (recorderRef.current) {
    recorderRef.current.stopRecording(() => {
      recorderRef.current = null;
    });
  }

  if (audioStreamRef.current) {
    audioStreamRef.current.getTracks().forEach((track) => track.stop());
    audioStreamRef.current = null;
  }

  if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
    wsRef.current.close(1000, "Recording stopped by user");
  }

  stopTimer();
  setElapsedTime(0);
 setIsRecording(false)
 setIsIndRecord(false)
 setRecordingField('')
 handlealertClick('Recording Has Been Completed','success')
 setTimeout(() => {
  navigate("/app");
}, 1500);
};
const onExit=()=>{
  if(isError){
    stopmanualRecording()
     stopRecording()
    setIsexitModal(false)

    return
  } 
  stopRecording()
setIsexitModal(false)
}
const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(reader.result.split(",")[1]);
    };
    reader.onerror = (error) => reject(error);
  });
};
const onDelete=async()=>{
  setDeleteConfirmationModal(true)

}
const deleteStream=async()=>{
  try {
   setIsLoading(true)
    const response=await api.post(`/v1/recording/streaming/cancel?stream_filename=${streamfilename}`)
    if(response.status===200){ 
      stopRecording()    
      setDeleteConfirmationModal(false)
handlealertClick('Your Recording is Successfully cancelled', 'error')
    }else{
      stopRecording()    
      setDeleteConfirmationModal(false)
handlealertClick('Your Recording is Successfully cancelled', 'error')
    }
    if(isError){
      blocker.current=true
      stopmanualRecording()
    }

  } catch (error) {
    console.log(error)
  }finally{
   setIsLoading(false)

  }
}
// style={{
//   background: 'linear-gradient(180deg, #FFF 34.38%, #A2C6FD 167.97%)'}}
  return (<>

    <div className="flex items-center justify-center w-full h-auto"

    >
      <div className="w-[95%] mb-2 sm:w-[80%] flex flex-col justify-center items-center  relative h-min-[150px] h-auto  p-2 pb-3 ">
    <div className='relative flex flex-col gap-2 sm:flex-row sm:gap-0 w-full justify-between items-center'>
    <div onClick={() => navigate(-1)} className="absolute left-2 mr-2 top-5 sm:bottom-3">
          <img src={arrowleft} alt="Down Arrow" className="w-5 h-5 cursor-pointer" />
        </div>
    <div
  className={`w-full mb-2 flex justify-center items-center`}
>
      <div className='flex  gap-4   items-center'>
      <div
  className="relative flex justify-center items-center w-[53px] h-[53px] rounded-full"
  style={circleStyle}
>
  <div className="flex justify-center items-center w-[40px] h-[40px] bg-[#FAFAF9] rounded-full">
    <img
      src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEXk5ueutLeqsbTn6eqpr7PJzc/j5ebf4eLZ3N2wtrnBxsjN0NLGysy6v8HT1tissra8wMNxTKO9AAAFDklEQVR4nO2d3XqDIAxAlfivoO//tEOZWzvbVTEpic252W3PF0gAIcsyRVEURVEURVEURVEURVEURVEURVEURVEURVEURflgAFL/AirAqzXO9R7XNBVcy9TbuMHmxjN6lr92cNVVLKEurVfK/zCORVvW8iUBnC02dj+Wpu0z0Y6QlaN5phcwZqjkOkK5HZyPAjkIjSO4fIdfcOwFKkJlX4zPu7Ha1tIcwR3wWxyFhRG6g4Je0YpSPDJCV8a2Sv2zd1O1x/2WMDZCwljH+clRrHfWCLGK8REMiql//2si5+DKWKcWeAGcFMzzNrXC/0TUwQ2s6+LhlcwjTMlYsUIQzPOCb7YBiyHopyLXIEKPEkI/TgeuiidK/R9FniUDOjRDpvm0RhqjMyyXNjDhCfIMYl1gGjIMIuYsnGEYRMRZOMMunaLVwpWRW008v6fYKDIzxCwVAeNSO90BJW6emelYBRF/kHpYGVaoxTDAaxOFsfP9y8hpJ4xd7gOcij7JNGQ1EYFgkPJa1jQEiYZXRaRINKxSDUW9n+FT82lSKadkiru9/4XPqSLWOekGPoY05TAvLm9orm+YWuwHoBHkZKijNBJGmeb61eL6Ff/6q7bLr7yvv3vKGhpDRjvgjGaPz+gUg6YgcvpyAR2FIZ9U6nEEyZRTovmEU32KichpGn7C17XrfyH9gK/c0CMP05HZIM2uf9sEveizKveBy9/6Qt7o89ne33D525cfcIMW6ab+TMEukQbQbu+xu7X3A9bChmWaCeAkG17bpntwXgWxHaMzGPmUaR5dQZiKqRVeUZ3047fi3nAu28h4CHxCsZAgmEH8Y27jJAhm8c+5RQzRQNVGhVFSfxOYIjp/pP7RxzjevYXVGf4eLt+BJ1vCuLuLkrgABgCGXZ2wik5uty+oBvNirI6mkzhAf4Gsb58Hcm67Jzd+KwD10BYPLL3e0MjvKrgAULnOfveF/O4N2Xb9BZom3gJes3F9X5Zze8/6Yt09b4CrqsEjUv8oFBaR2rl+6CZr2xVrp24o/WitBKuGrrpl1+bFkmK2qXTON4VpbdfLa7o7y/WdLxG7lm2Lqh2clOwTegbvc/vj2U78CwhA87Bn8G5Nk3eOb0Nsr9flz3sG78UUtue4kpv1xvjg3TMay62BMlTlP+vrOMnJsRmt/ze0jsfkPPYdAH57hK+34PeOyc8XIXu5xT2HsUkdZz+adwg8HGFfQ3K5jtDvbUiO4Di9/ywHGrL88pDizZ++oTp+an+SMX/ndymUCwmHMdO7yuOx83pUx/eEMU0AvxWndwgidAqOZ8ypCwdEfvvEo6D9HwpA8wzvmOJEqAg9ySu8g4x0Hb9hSB/BANEKJ+LbPBU0lzbAJs4xt1AoshKkUGQmiH8/jJ0gdhTTLmSegHlPE0oOdXALnqDjKYh3px//fSgSWG8UqfrrIICzYYSJXRr9BSPbpNzw7gBjKjKOYI7ReIGqQRIap5+5MdjyvuDkExvGeXSlONWZAP3/AZBwJohU7QJRGU+cTVH18ELmRPNBmibW6MT/k1b0XhdkRBvyT6SB6EYv/GvhSmRNpGngRULsAlxMCGNXp7w3FfdEbTEEDdLI9TdIKRUzUesa3I461ER8cpNT7gMRhpKmYVS9ELOgCUQsa4SsulciKiLbY+AnHD8cpuhISsnxpamI84sbDq9qYJgf8wiiOBrC7Ml7M7ZECCqKoiiKoiiKoiiKoijv5AvJxlZRyNWWLwAAAABJRU5ErkJggg=='}
      alt="profile"
      className="flex justify-center items-center w-[30px] h-[30px] bg-[#C4C4C4] rounded-full"
    />
  </div>
</div>
    <div className="text-start font-sans font-medium">
  {data?.config?.patient_name || data?.config?.patientid ? (
    <div className="text-sm text-gray-600">
      {data?.config?.patient_name} {data?.config?.patientid ? `(${data?.config?.patientid})` : ''}
    </div>
  ) : null}
</div>

          </div>
      </div>
   
      <div className='flex min-w-[600px]  gap-4 items-center justify-center'>
  <div 
        onClick={()=>{handleCopyallText()}}
        className="flex flex-row h-auto sm:h-9  gap-2  items-center  cursor-pointer p-2 rounded-md border border-gray-100 bg-white shadow-[0px_4px_16px_0px_rgba(20,25,26,0.08)]">
        
            <img style={{ width: '13px', height: '13px' }} src={Copyall} alt="&/" /> 
            <div className="text-xs sm:text-sm"> Copy All</div>
        
        </div>
      
 <div className='flex flex-col gap-4 items-center justify-center'>
        
     
 {isIndRecord ? (
  <div>
    { !mydata.isStreaming ?
 <IndividualRecording screenname={recordingField}
 setIndIsRecording={setIndIsRecording}
 submitIndAudio={submitIndAudio}
 setToastmsg={setToastObject}
 setToast={setToast}
 setIsdelted={setIndIsDeletedAudio}
 />:<>
 <ArcheiveStreaming
 setIsexitModal={setIsexitModal}
stopRecording={stopRecording}
elapsedTime={elapsedTime}
formatTime={formatTime}
togglePause={togglePause}
isPaused={isPaused}
setIsPaused={setIsPaused}
stopTimer={stopTimer}
pausedref={pausedref}
audioStreamRef={audioStreamRef}
onDelete={onDelete}
 />
 </>}
   </div>
) : isRecording ? (
  !mydata.isStreaming ?
  <ArchiveRecordingScreen
    setIsRecording={setIsRecording}
    submitAudio={submitAudio}
    setToastmsg={setToastObject}
    setToast={setToast}
    setIsdelted={setIsdelted}

  />
 :<><ArcheiveStreaming
 setIsexitModal={setIsexitModal}
stopRecording={stopRecording}
elapsedTime={elapsedTime}
formatTime={formatTime}
togglePause={togglePause}
isPaused={isPaused}
setIsPaused={setIsPaused}
stopTimer={stopTimer}
pausedref={pausedref}
audioStreamRef={audioStreamRef}
onDelete={onDelete}
 /></>): (
  <>
    <div
      onClick={() => {
        if(mydata.isOpen){
          setAlertMessage('Recording already in progress');
          setSeverity('info'); // Change severity as needed
          setOpen(true);
        return
        }
if(mydata.isStreaming ){
  startRecording()
}
        setIsRecording(true);

        setIsdelted(false);
        handlealertClick('Recording Started');
      }}
      className="relative flex items-center justify-center"
    >
      <img src={redmic} className="w-[50px] h-[50px]" alt="Mic" />
    </div>
    {/* <div className="flex justify-center items-center mt-4">
      <div className="text-sm items-center [font-family:'Poppins',Helvetica] text-[#121212] tracking-[0] leading-[0.1px]">
        Add Recording
      </div>
    </div> */}
  </>
)}

        </div>
       

{mydata.usertype=='PLUS'?
    <div 
    onClick={()=>navigate(`/modify-template/${id}`)}
    className="inline-flex h-auto sm:h-9  items-center cursor-pointer p-2 rounded-md border border-gray-100 bg-white shadow-[0px_4px_16px_0px_rgba(20,25,26,0.08)]">
    <img src={adveditpng} alt="Advanced Editing" className="mr-2" />
    <div className='text-xs sm:text-xs font-normal'>Advanced Editing</div> 
  </div>:<></>
  }
        </div>
       

        <div className='flex w-full justify-end items-center'>
        {data?.transcriptionstatus==='Summarized'?<>
            <button
      onClick={()=>{handleSignoff()}}
      disabled={isbuttonloading} // Disable the button during loading
      className={`px-6 py-2 rounded-lg  text-white text-[10px] whitespace-nowrap ${
        isbuttonloading ? 'bg-gray-500 cursor-not-allowed opacity-50' : 'bg-[#1C2A3A] cursor-pointer opacity-100'
      }`}
    >
      {isbuttonloading ? 'Signing Off...' : 'Sign Off'}
    </button>
          
          
          </>:<></>}
          <div className='flex items-center gap-4 sm:ml-14'>
          <DashboardOutlinedIcon
      onClick={()=>setView(2)}
        style={{
          color: view===2 ? 'black' : 'grey', // Change the color based on isGridView
          cursor: 'pointer',
           fontSize:"26px"
        }}
      />
          <SplitscreenOutlinedIcon
      onClick={()=>setView(1)}
        style={{
          color: view===1 ? 'black' : 'grey', // Change the color based on isGridView
          cursor: 'pointer',
           fontSize:"26px"
        }}
      />
  <MenuIcon
  onClick={()=>{setView(0)}}
        style={{
          color: view===0?'black' : 'grey', // Change the color based on isGridView
          cursor: 'pointer',
          fontSize:"30px"
        }}
      />


  </div></div>
       
    </div>
{view===0?
<div className='w-full mt-[4%] items-center  justify-center flex flex-col gap-4'>
{jsonData.map((section, idx) => (
<>
{
  summaryjson?.[section.placeholder?.toLowerCase()]?.length > 0 ?
<div  
 onClick={()=>{
  setSectionData(section)
  setPopUp(true)
}}

key={idx} className="flex cursor-pointer flex-col sm:flex-row justify-between items-center w-full sm:w-[80%] h-auto sm:h-20 rounded-lg shadow-md pr-0 sm:pr-4 border-l-4 border-[#A2C6FD] border-t border-b border-t-gray-400 border-b-gray-400 bg-white">
  <div className="flex items-center justify-center w-full sm:w-[20%] sm:min-w-[20%] sm:max-w-[20%] h-full bg-blue-100 p-0 rounded-md">
    <h1 className="text-sm font-bold text-blue-600">{section.label
    ? section.label
        .toLowerCase() // Ensure all letters are lowercase first
        .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
    : ''}</h1>
  </div>
  <div className='w-full flex justify-start'>

  <div 
  className="mt-2 font-sans text-[#374151] px-4 text-left"
  dangerouslySetInnerHTML={{
    __html: summaryjson?.[section.placeholder]?.length > 100
      ? `${summaryjson[section.placeholder].substring(0, 100)}...`.replace(/\n/g, '<br />')
      : summaryjson[section.placeholder]?.replace(/\n/g, '<br />')
  }}
></div>


  </div>


  <div className='flex gap-4 cursor-pointer justify-end items-center w-full sm:min-w-[20%] sm:max-w-[20%]'>
  {['review of systems', 'physical examination'].includes(section.label) ? (
    <div className={`inline-block px-3 py-1 text-xs rounded-full shadow-md ${summaryjson[section.placeholder].replace(/\s|\./g, '') === 'Normal' ? 'text-green-700 bg-green-200' : 'text-red-700 bg-red-200'}`}>
        {summaryjson[section.placeholder].replace(/\s|\./g, '') === 'Normal' ?'Normal':'Abnormal'}
    </div>
) : null}

<div className='flex gap-4 items-center justify-center flex-row '>
{section?.label==='doctor instructions'?<ShareOutlinedIcon
  onClick={(event) => {
    event.stopPropagation(); // Prevent event from bubbling up
    setSelectedCard(section);
    setIsShareModal(true);
  }}
  sx={{
    fontSize: "18px",
    color: "#676767",
    cursor: "pointer",
    marginTop: "2rem",
  }}
/>
:<></>}
<FontAwesomeIcon 
      onClick={()=>{
        setSectionData(section)
        setFields(prevFields => ({ ...prevFields, [section.key]: formatToText(section.value)}))
        setPopUp(true)
      }}
      icon={faUpRightFromSquare} className="text-[#7E7E7E] mt-8 cursor-pointer" />


</div>

  </div>

</div>
:<></>
}
</>
))
}</div>:
(view===1?
<>
<div className="w-full mt-[4%] flex flex-col justify-center gap-4">
  {jsonData.map((section, idx) => (<>
  {
    summaryjson?.[section.placeholder?.toLowerCase()]?.length > 0 ?
    <div
      key={idx}
      className="flex flex-col justify-between items-center w-auto max-w-full"
      style={{
        borderRadius: "8px",
        border: "1px solid rgba(162, 198, 253, 0.40)",
        background: "#FFF",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
        flex: '1 0 auto', // Ensures it grows dynamically
      }}
    >
      <div className="flex items-center justify-between w-full h-10 px-4 bg-blue-100 p-0 rounded-md">
        <h1 className="text-sm font-bold text-[#262629]">
          {section.label
            ? section.label
                .toLowerCase() // Ensure all letters are lowercase first
                .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
            : ''}
        </h1>

        <div className='flex gap-4 items-center'>
        {section?.label==='doctor instructions'?<ShareOutlinedIcon
         onClick={()=>{
          setSelectedCard(section)
          setIsShareModal(true)
        }}
        sx={{fontSize:"18px",color:"#676767",cursor:"pointer"}}
        />:<></>}
        

        <FontAwesomeIcon 
           onClick={()=>{
            setSectionData(section)
            setFields(prevFields => ({ ...prevFields, [section.key]: formatToText(section.value)}))
            setPopUp(true)
          }}
        icon={faUpRightFromSquare} className="text-[#7E7E7E] cursor-pointer" />
        </div>
      
      </div>
      <div className="w-full flex justify-start">
        {/* <div
     
          className="mt-2 font-sans text-[#374151] px-4 text-left"
        >
          {summaryjson[section.placeholder]}
        </div> */}
        <div 
  className="mt-2 font-sans text-[#374151] px-4 text-left"
  dangerouslySetInnerHTML={{
    __html: summaryjson?.[section.placeholder]?.length > 100
      ? `${summaryjson[section.placeholder].substring(0, 100)}...`.replace(/\n/g, '<br />')
      : summaryjson[section.placeholder]?.replace(/\n/g, '<br />')
  }}
></div>

      </div>
      <div className="flex mb-4 justify-end items-center w-full px-4">
        {['review of systems', 'physical examination'].includes(section.label) ? (
    <div className={`inline-block px-3 py-1 text-xs rounded-full shadow-md ${summaryjson[section.placeholder].replace(/\s|\./g, '') === 'Normal' ? 'text-green-700 bg-green-200' : 'text-red-700 bg-red-200'}`}>
        {summaryjson[section.placeholder].replace(/\s|\./g, '') === 'Normal' ?'Normal':'Abnormal'}
    </div>
) : null}
      </div>
    </div>
:<></>}
    </>
  ))}
</div>




</>:<>
<div className="w-full mt-[4%] grid grid-cols-1 sm:grid-cols-3 gap-4">
  {jsonData.map((section, idx) => {
    let customClasses = "";

    // Determine layout based on repeating pattern
    const positionInPattern = idx % 7; // Pattern repeats every 7 items

    if (positionInPattern === 0 || positionInPattern === 1 || positionInPattern === 2) {
      // First row: 3 equal-width boxes
      customClasses = "col-span-3 sm:col-span-1 min-h-[120px]";
    } else if (positionInPattern === 3) {
      // Second row: 1 full-width box
      customClasses = "col-span-3 min-h-[120px]";
    } else if (positionInPattern === 4) {
      // Third row: less-width box
      customClasses = "col-span-3 sm:col-span-1 min-h-[120px]";
    } else if (positionInPattern === 5) {
      // Third row: more-width box
      customClasses = "col-span-3 sm:col-span-2 min-h-[120px]";
    } else if (positionInPattern === 6) {
      // Fourth row: full-width box
      customClasses = "col-span-3 min-h-[120px]";
    }

    return (<>
    {
      summaryjson?.[section.placeholder?.toLowerCase()]?.length > 0 ?
      <div
        key={idx}
        className={`relative flex flex-col justify-start items-start w-auto max-w-full ${customClasses}`}
        style={{
          borderRadius: "8px",
          border: "1px solid rgba(162, 198, 253, 0.40)",
          background: "#FFF",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          flex: "1 0 auto",
        }}
      >
        <div className="flex items-center justify-between w-full h-10 px-4 bg-blue-100 p-0 rounded-md">
          <h1 className="text-sm font-bold text-[#262629]">
            {section.label
              ? section.label
                  .toLowerCase()
                  .replace(/\b\w/g, (char) => char.toUpperCase())
              : ""}
          </h1>
       
         
        

<div className='flex items-center gap-4'>
{section?.label==='doctor instructions'?<ShareOutlinedIcon
         onClick={()=>{
          setSelectedCard(section)
          setIsShareModal(true)
        }}
        sx={{fontSize:"18px",color:"#676767",cursor:"pointer"}}
        />:<></>}
   <FontAwesomeIcon
            onClick={() => {
              setSectionData(section);
              setFields(prevFields => ({ ...prevFields, [section.key]: formatToText(section.value)}))
              setPopUp(true);
            }}
            icon={faUpRightFromSquare}
            className="text-[#7E7E7E] cursor-pointer"
          />
</div>
        
         
        </div>
        <div className="w-full flex justify-start">
          {/* <div className="mt-2 font-sans text-[#374151] px-4 text-left">
            {summaryjson[section.placeholder]?.length > 300
              ? `${summaryjson[section.placeholder].substring(0, 300)}...`
              : summaryjson[section.placeholder]}
          </div> */}
          <div 
  className="mt-2 font-sans text-[#374151] px-4 text-left"
  dangerouslySetInnerHTML={{
    __html: summaryjson?.[section.placeholder]?.length > 100
      ? `${summaryjson[section.placeholder].substring(0, 100)}...`.replace(/\n/g, '<br />')
      : summaryjson[section.placeholder]?.replace(/\n/g, '<br />')
  }}
></div>
        </div>
        <div className="flex absolute bottom-2 justify-end items-center w-full px-4">
        {['review of systems', 'physical examination'].includes(section.label) ? (
    <div className={`inline-block px-3 py-1 text-xs rounded-full shadow-md ${summaryjson[section.placeholder].replace(/\s|\./g, '') === 'Normal' ? 'text-green-700 bg-green-200' : 'text-red-700 bg-red-200'}`}>
        {summaryjson[section.placeholder].replace(/\s|\./g, '') === 'Normal' ?'Normal':'Abnormal'}
    </div>
) : null}

      </div>
      </div>
 :<></> }</> );
  })}
</div>
</>
)
}

        <div className="flex items-center p-4 mt-6 justify-center gap-4">
          <button 
            onClick={handleSaveButtonClick} 
            className="px-16 py-2 rounded-full bg-[#1C2A3A] font-[Tahoma] text-white text-xs"
          >
            Save
          </button>
          {/* {data?.transcriptionstatus==='Summarized'?<>
            <button
      onClick={()=>{handleSignoff()}}
      disabled={isbuttonloading} // Disable the button during loading
      className={`px-16 py-2 rounded-full text-white  text-xs ${
        isbuttonloading ? 'bg-gray-500 cursor-not-allowed opacity-50' : 'bg-[#1C2A3A] cursor-pointer opacity-100'
      }`}
    >
      {isbuttonloading ? 'Signing Off...' : 'Sign Off'}
    </button>
          
          
          </>:<></>} */}
        </div>
      </div>
    </div>
    {popup ? (
  <>
    {/* Dark Background Overlay */}
    <div
      className="fixed inset-0 bg-black bg-opacity-50"
      onClick={() => setPopUp(false)} // Close popup on clicking outside
    ></div>

    {/* Popup Content */}
    <div
      className="flex flex-col h-full shadow-[0px_2px_13px_#00000040] p-4 gap-2 w-full max-w-[550px] sm:max-w-[60%] sm:min-h-[400px] sm:h-[80vh]  sm:max-h-[90vh] bg-white fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      style={{
        borderRadius: "8px",
        background: "white",
        // height: "80vh", // Fixed height
        // maxHeight: "90vh", // Prevents overflow on smaller screens
        overflow: "hidden", // Prevents popup from growing beyond fixed height
      }}
    >
      
      {/* Section Label */}
      <div className="flex justify-between w-full mb-4">
        <div style={{ fontWeight: 500 }} className="text-sm">
          {sectiondata.label
            ? sectiondata.label
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase())
            : ""}
        </div>

      

        <CloseIcon
          style={{ cursor: "pointer", color: "#000" }}
          onClick={() => setPopUp(false)}
        />
      </div>

      {/* Textarea */}
      <div className="h-full flex-grow overflow-hidden">
  <ReactQuill
    className="w-full text-start flex-grow custom-scrollbar p-2 font-inter"
    style={{
      borderRadius: "8px",
      border: "1px solid #A2C6FD",
      background: "#FFF",
      boxShadow: "0px 5px 15px 0px rgba(119, 132, 238, 0.30)",
      minHeight: "200px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      overflow:"hidden"
    }}
    value={fields[sectiondata.key]?.replace(/\n/g, "<br/>")}
    onChange={(value) => handleFieldChange(sectiondata.key, value)}
    placeholder={sectiondata.placeholder}
    modules={{
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
        ["blockquote", "code-block"],
        // ["link", "image", "video"],
        ["clean"],
      ],
    }}
    formats={[
      "font", "size", "header",
      "bold", "italic", "underline", "strike",
      "color", "background",
      "script", "sub", "super",
      "list", "bullet", "indent",
      "align", "blockquote", "code-block",
      // "link", "image", "video"
    ]}
  />
</div>



      {/* Footer Section */}
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center gap-4">
          {/* Copy Icon */}
          <div>
            <img
              style={{ width: "18px", height: "18px", cursor: "pointer" }}
              src={copydark}
              alt="Copy"
              onClick={() => copyToClipboard(fields[sectiondata.key])}
            />
          </div>

          {/* Print Icon */}
          <div>
            <img
              style={{ width: "13px", height: "13px", cursor: "pointer" }}
              src={printdark}
              alt="Print"
              onClick={() => {
                if (data.transcriptionstatus === "Processing") {
                  setAlertMessage("Update in process. Hold on!");
                  setSeverity("info");
                  setOpen(true);
                  return;
                }

                handleSaveIconClick(sectiondata.key);
              }}
            />
          </div>
        </div>

        {/* Mic Icon */}
        <div className="flex items-center gap-2">
          <div
            onClick={() => {
              if (recordingField === sectiondata.key) {
                return;
              }
              if (recordingField) {
                return;
              }
              if (data.transcriptionstatus === "Processing") {
                setAlertMessage("Update in process. Hold on!");
                setSeverity("info");
                setOpen(true);
                return;
              }
              if (mydata.isOpen) {
                setAlertMessage("Recording already in progress");
                setSeverity("info");
                setOpen(true);
                return;
              }
              setRecordingField(sectiondata.key);
              setIsIndRecord(true);
              if(mydata.isStreaming ){
                startRecording(sectiondata.key)
              }
              window.scrollTo({ top: 0, behavior: "smooth" });
              setPopUp(false);
            }}
          >
            <MicIcon
              fontSize="small"
              style={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
                color: recordingField === sectiondata ? "red" : "#000",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </>
) : (
  <></>
)}

      {/* Copy feedback message */}
      <Snackbar
  open={copyFeedback}
  autoHideDuration={3000}
  // onClose={handleClose}
  message="Copied to clipboard!"
  // action={action}
/>
<ToastModal toastobject={toastobject} isModalOpen={toast} setModalOpen={setToast}/>
     <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
     <CircularProgress color="inherit" />
   </Backdrop>
   <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
      <ExitModal savemodal={isExitModal} setModalOpen={setIsexitModal} onExit={onExit}/>
   <ShareModal 
   setSeverity={setSeverity}
   data={data}
   setAlertMessage={setAlertMessage}
   setOpen={setOpen}
   selectedCard={selectedCard}  isOpen={isSharemodal} onClose={setIsShareModal}/>
    <DeleteConfirmation savemodal={deleteconfirmationModal} setModalOpen={setDeleteConfirmationModal} onExit={deleteStream}/>
    </>
  );
};

export default BasicEditscreen;
