import { useState, useRef } from "react";

const useAudioRecorder = (onSaving) => {
  const [audioBlob, setAudioBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false); // Add state to track recording status
  const recordingRef = useRef(null);
  const chunksRef = useRef([]);

  const manualrecord = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      recordingRef.current = new MediaRecorder(stream);
      
      recordingRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };
      
      recordingRef.current.onstop = () => {
        const audioBlob = new Blob(chunksRef.current, { type: "audio/webm" });
        setAudioBlob(audioBlob);
        onSaving(audioBlob); // Sending blob file to API
        chunksRef.current = [];
        setIsRecording(false); // Set recording status to false when recording stops
      };
      
      recordingRef.current.start();
      setIsRecording(true); // Set recording status to true when recording starts
    } catch (error) {
      console.error("Error accessing microphone", error);
    }
  };

  const stopmanualRecording = () => {
    if (isRecording && recordingRef.current && recordingRef.current.state !== "inactive") {
      recordingRef.current.stop();
      setIsRecording(false); // Set recording status to false when stopping
    } else {
      console.log("No recording in progress.");
    }
  };

  const pausemanualRecording = () => {
    if (isRecording && recordingRef.current && recordingRef.current.state === "recording") {
      recordingRef.current.pause();
    } else {
      console.log("Recording is not in progress or already paused.");
    }
  };

  const resumemanualRecording = () => {
    if (isRecording && recordingRef.current && recordingRef.current.state === "paused") {
      recordingRef.current.resume();
    } else {
      console.log("Recording is not paused.");
    }
  };

  return { audioBlob, manualrecord, stopmanualRecording, pausemanualRecording, resumemanualRecording };
};

export default useAudioRecorder;
