import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faTimes } from '@fortawesome/free-solid-svg-icons';  // Import FontAwesome close icon

const ConsentAcceptance = ({  setModal,handleAcceptance}) => {
  



  return (
    <>
      <div
        id="popup-modal"
        tabIndex="-1"
        className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
      >
        <div className="relative p-4 w-full max-w-lg max-h-full">
          <div className="relative bg-white rounded-lg shadow">
            {/* Header section with title and close icon */}
            <div className="flex justify-between items-center p-4 pb-0">
              <h2 className="text-lg font-semibold">Consent for Audio Recording</h2>
              <button
                onClick={() => setModal(false)} // Close the modal when clicked
                className="text-gray-500 hover:text-gray-700"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>

           
            <div className="p-4 text-sm  text-start text-gray-600">
         
                The patient has provided verbal/written consent for audio
                recording in compliance with applicable state and federal laws.
             
            </div>

            {/* Footer with Accept/Decline buttons */}
            <div className="flex justify-end p-4">
             
              <button
                onClick={() => setModal(false)} // Close the modal or proceed with declining
                className="bg-[#F4F4F5] text-black rounded-lg text-sm px-4 py-2 mr-2 "
              >
                Decline
              </button>
              <button
                onClick={() => handleAcceptance()} // Close the modal or proceed with accepting
                className="bg-[#1C2A3A] text-white rounded-lg text-sm font-semibold px-4 py-2  "
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsentAcceptance;
