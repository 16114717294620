import React, { useState, useRef } from "react";

const VoiceRecordingStream = () => {
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0); // Total elapsed time in seconds
  const startTimeRef = useRef(null); // Tracks the timer's start/resume time
  const timerRef = useRef(null); // Reference to the timer interval
  const audioStreamRef = useRef(null);
  const wsRef = useRef(null);
  const token = sessionStorage.getItem("auth");

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const startTimer = () => {
    startTimeRef.current = Date.now() - elapsedTime * 1000; // Adjust for any previously elapsed time
    timerRef.current = setInterval(() => {
      const timePassed = Math.floor((Date.now() - startTimeRef.current) / 1000);
      setElapsedTime(timePassed);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
  };

  const togglePause = () => {
    if (isPaused) {
      // Resume the timer
      startTimer();
    } else {
      // Pause the timer
      stopTimer();
    }
    setIsPaused((prev) => !prev);
  };

  const startRecording = async () => {
    try {
      const filename = crypto.randomUUID();
      const practiceid = "9928";
      const departmentid = "1";
      const patientid = "2336";
      const appointmentid = "581141";
      const notes = "Sample notes";
      const tags = "Sample tags";
      const patientname = "Teja Pamganamamula";

      const ws = new WebSocket(
        `wss://api.prd.brainymed.com/bs/v1/recording/streaming?token=${token}&stream_filename=${filename}&practiceid=${practiceid}&departmentid=${departmentid}&patientid=${patientid}&appointmentid=${appointmentid}&notes=${encodeURIComponent(
          notes
        )}&tags=${encodeURIComponent(tags)}&patientname=${encodeURIComponent(patientname)}`
      );

      wsRef.current = ws;

      ws.onopen = () => {
        setConnectionStatus("Connected");
        console.log("WebSocket connection established.");
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.onclose = () => {
        setConnectionStatus("Disconnected");
        console.log("WebSocket connection closed.");
      };

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStreamRef.current = stream;

      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const processor = audioContext.createScriptProcessor(4096, 1, 1);

      source.connect(processor);
      processor.connect(audioContext.destination);

      let lastSentTime = 0;
      processor.onaudioprocess = (event) => {
        if (ws.readyState === WebSocket.OPEN && !isPaused) {
          const currentTime = Date.now();
          // Only send audio data every 5 seconds
          if (currentTime - lastSentTime >= 5000) {
            const audioData = event.inputBuffer.getChannelData(0);
            const float32ToInt16 = (float32Array) => {
              const int16Array = new Int16Array(float32Array.length);
              for (let i = 0; i < float32Array.length; i++) {
                int16Array[i] = Math.min(1, float32Array[i]) * 0x7fff;
              }
              return int16Array;
            };
            ws.send(float32ToInt16(audioData).buffer);
            lastSentTime = currentTime;
          }
        }
      };

      setElapsedTime(0); // Reset timer
      startTimer(); // Start timer
      setIsRecording(true);
      setIsPaused(false);
    } catch (error) {
      console.error("Error starting recording:", error);
      setConnectionStatus("Error");
    }
  };

  const stopRecording = () => {
    if (audioStreamRef.current) {
      const tracks = audioStreamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      audioStreamRef.current = null;
    }

    if (wsRef.current) {
      if (wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ type: "close" }));
      }
      wsRef.current.close();
    }

    stopTimer();
    setElapsedTime(0);
    setIsRecording(false);
    setIsPaused(false);
  };

  return (
    <div className="flex flex-col items-center justify-center p-4 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Voice Recording Stream</h1>
      <div className="flex items-center justify-center bg-white shadow-lg p-6 rounded-lg">
        <div className="flex flex-col items-center">
          <div className="text-3xl font-mono mb-2">{formatTime(elapsedTime)}</div>
          {isRecording ? (
            <>
              <div className="text-red-500 font-bold mb-4 animate-pulse">
                Recording...
              </div>
              <button
                className={`bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition duration-200 mb-2 ${isPaused ? "bg-yellow-300" : ""}`}
                onClick={togglePause}
              >
                {isPaused ? "Resume" : "Pause"}
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200"
                onClick={stopRecording}
              >
                Stop Recording
              </button>
            </>
          ) : (
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
              onClick={startRecording}
            >
              Start Recording
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VoiceRecordingStream;
