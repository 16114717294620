import React, { useState } from "react";

const Meeet = () => {
  const [text, setText] = useState("");
  const [meetLink, setMeetLink] = useState("");

  const startListening = () => {
    if (!("webkitSpeechRecognition" in window) && !("SpeechRecognition" in window)) {
      alert("Speech recognition is not supported in this browser.");
      return;
    }

    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      let transcript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        transcript += event.results[i][0].transcript + " ";
      }
      setText(transcript);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };

    recognition.start();
  };

  const joinMeet = () => {
    if (meetLink.startsWith("https://meet.google.com/")) {
      window.open(meetLink, "_blank");
      startListening();
    } else {
      alert("Enter a valid Google Meet link.");
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2>Google Meet Transcription</h2>
      <input
        type="text"
        placeholder="Enter Google Meet Link"
        value={meetLink}
        onChange={(e) => setMeetLink(e.target.value)}
        style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
      />
      <button onClick={joinMeet} style={{ padding: "10px 20px", cursor: "pointer" }}>
        Join Meet & Start Transcription
      </button>
      <h3>Transcription:</h3>
      <p style={{ background: "#f4f4f4", padding: "10px", minHeight: "100px" }}>
        {text || "Your speech will appear here..."}
      </p>
    </div>
  );
};

export default Meeet;
