import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetData, resetSettings, setProviders, setVisittype } from '../redux/store';

const PaymentStatus = () => {
  const { status } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5); // Initialize countdown to 5 seconds

  useEffect(() => {
    // Countdown logic
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    // Redirect and logout after countdown reaches 0
    if (countdown === 0) {
      logout();
    }

    return () => clearInterval(interval);
  }, [countdown]);

  const logout = () => {
    dispatch(resetSettings());
    dispatch(setProviders([]));
    dispatch(setVisittype([]));
    dispatch(resetData());
    localStorage.removeItem('mytab');
    sessionStorage.removeItem('auth');
    navigate('/auth'); // Redirect to login page
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <motion.div
        className={`max-w-md w-full p-8 rounded-lg shadow-xl`}
        style={{
          background: 'linear-gradient(180deg, rgb(255, 255, 255) 20%, rgb(162, 198, 253) 100%)',
        }}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="text-center">
          <motion.h2
            className={`text-4xl font-extrabold mb-4 ${status === 'success' ? 'text-green-600' : 'text-red-600'}`}
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {status === 'success' ? 'Payment Successful' : 'Payment Failed'}
          </motion.h2>
          <motion.p
            className="text-xl mb-6 text-gray-800"
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {status === 'success'
              ? 'Your payment has been processed successfully. You will be automatically redirected to the login page.'
              : 'Oops! There was an issue with your payment. You will be redirected to the login page in a few seconds.'}
          </motion.p>
          <motion.div
            className="text-lg font-semibold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <p>Redirecting in {countdown}...</p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default PaymentStatus;
