import React, { useState } from "react";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Flag from "react-world-flags";
import api from "api/api";
const countries = [
  { code: "US", prefix: "+1", label: "United States", value: "US", minLength: 10 },
  { code: "IN", prefix: "+91", label: "India", value: "IN", minLength: 10 },
  { code: "GB", prefix: "+44", label: "United Kingdom", value: "GB", minLength: 10 },
  { code: "DE", prefix: "+49", label: "Germany", value: "DE", minLength: 11 },
  { code: "FR", prefix: "+33", label: "France", value: "FR", minLength: 9 },
];
const ShareModal = ({ isOpen, selectedCard, onClose,data,setSeverity,setAlertMessage,setOpen}) => {
  const [link] = useState("http://localhost:3000/basic-archive-edit/3347/tony");
  const [isCopied, setIsCopied] = useState(false);
  const [isEmailSelected, setIsEmailSelected] = useState(true); // Default to email
  const [isPhoneSelected, setIsPhoneSelected] = useState(false); // Default to phone not selected
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const[isSubmitting,SetIsSubmitting]=useState('')
  const [Error, setError] = useState(""); // To store email error message
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((country) => country.value === "US")
  );
  const customStyles = {
      control: (provided) => ({
        ...provided,
        border: "none",
      }),
      option: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        fontSize: "8px",
      }),
      singleValue: (provided) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
      }),
    };
  
    const formatOptionLabel = ({ code, label }) => (
      <div className="flex items-center">
        <Flag
          code={code}
          className="mr-2"
          style={{ width: "20px", height: "15px" }}
        />
        
      </div>
    );
  if (!isOpen) return null;

  const handleCopy = () => {
    if (isCopied) {
      return;
    }
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 4000);
  };

  const handleEmail = () => {
    setIsEmailSelected(true);
    setIsPhoneSelected(false); // Deselect phone number input
  };

  const handlePhone = () => {
    setIsPhoneSelected(true);
    setIsEmailSelected(false); // Deselect email input
  };

  const handleSendEmail = async() => {
 SetIsSubmitting(true)

 try {
  const response = await api.post("/v1/recording/doctor-instructions/email",{
      "email": email,
    "file_name": data.audiolink

  })
  if (response.status === 200) {
    setSeverity('success');
    setAlertMessage('link has been sent successfully.');
    setOpen(true);
    setEmail('')
  
  } else {
    setSeverity("info");
    setAlertMessage(response.message || "Failed to send link.");
    setOpen(true);
   
  }
} catch (error) {
  console.error(error);
  setSeverity("error");
  setAlertMessage("Something went wrong. Please try again.");
  setOpen(true);
}finally{
  SetIsSubmitting(false)
 }
  };

  const handleSendPhone = async() => {
    if (phoneNumber.length < selectedCountry.minLength) {
 
      setSeverity("error");
      setAlertMessage(`Phone number should be at least ${selectedCountry.minLength} digits long.`);
      setOpen(true)
      return
    }
SetIsSubmitting(true)
try {
  const response = await api.post("/v1/recording/doctor-instructions/mobile",{
    "country_code": selectedCountry.prefix,
  "mobile_number": phoneNumber,
  "file_name": data.audiolink

})
if (response.status === 200) {
  setSeverity('success');
  setAlertMessage('link has been sent successfully.');
  setOpen(true);
setPhoneNumber('')

} else {
  setSeverity("info");
  setAlertMessage(response.message || "Failed to send link.");
  setOpen(true);
 
}
} catch (error) {
  console.error(error);
  setSeverity("error");
  setAlertMessage("Something went wrong. Please try again.");
  setOpen(true);
}finally{
SetIsSubmitting(false)
}

  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Ensuring that only numbers are input
      setPhoneNumber(value);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

  
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-2">
            <ShareOutlinedIcon />
          </div>
          <div>
            <h2 className="text-xl sm:text-2xl font-bold">
              Share {selectedCard.label ? selectedCard.label.charAt(0).toUpperCase() + selectedCard.label.slice(1) : ""}
            </h2>
          </div>
          {/* Close Button */}
          <button
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={() => {
              onClose(false);
            }}
          >
            <CloseOutlinedIcon sx={{ color: "black" }} />
          </button>
        </div>

        {/* Content Section */}
        <div className="grid grid-cols-2 gap-4 mb-4">
          {/* Email Box */}
          <div
            className={`rounded-lg border ${isEmailSelected ? " border-2 border-blue-500" : "border-gray-300"} flex justify-center items-center h-16 gap-2 bg-white cursor-pointer`}
            onClick={handleEmail}
          >
            <div className="flex items-center gap-2 font-semibold">
              <div
                className="flex items-center justify-center rounded-full bg-[#3B82F6] p-2"
                style={{ background: "var(--color-azure-60, #3B82F6)" }}
              >
                <EmailOutlinedIcon sx={{ fontSize: "16px", color: "white" }} />
              </div>
              <h3 className="text-sm whitespace-nowrap">Email</h3>
            </div>
          </div>
          {/* Phone Number Box */}
          <div
            className={`rounded-lg border ${isPhoneSelected ? " border-2 border-green-500" : "border-gray-300"} flex justify-center items-center h-16 gap-2 bg-white cursor-pointer`}
            onClick={handlePhone}
          >
            <div className="flex items-center gap-2 font-semibold">
              <div
                className="flex items-center justify-center rounded-full bg-[#22C55E] p-2"
                style={{ background: "var(--color-spring-green-45, #22C55E)" }}
              >
                <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: "16px", color: "white" }} />
              </div>
              <h3 className="text-sm whitespace-nowrap">Text Message</h3>
            </div>
          </div>
        </div>

        {/* Email and Phone Input Sections */}
        {isEmailSelected && (
          <div className="mb-4">
            <input
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={handleEmailChange}
              className="w-full p-2 border border-gray-300 rounded-lg mb-2"
            />
          <button
      onClick={handleSendEmail}
      disabled={!validateEmail(email) || isSubmitting}
      className={`w-full p-2 rounded-lg ${!validateEmail(email) || isSubmitting ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
    >
      {isSubmitting ? (
        <>
          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
          Sending...
        </>
      ) : (
        'Send Email'
      )}
    </button>
          </div>
        )}
        {isPhoneSelected && (
          <div className="mb-4">
            <div className="flex">

                <Select
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                  options={countries}
                  styles={customStyles}
                  formatOptionLabel={formatOptionLabel}
                />
            <input
              type="text"
              placeholder="Enter Phone Number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className="w-full p-2 border border-gray-300 rounded-lg mb-2"
              maxLength={15}
            />
            </div>
           
            <button
              onClick={handleSendPhone}
              className="w-full bg-green-500 text-white p-2 rounded-lg"
            >
              {isSubmitting ? (
        <>
          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
          Sending...
        </>
      ) : (
        'Send Message'
      )}
              
            </button>
          </div>
        )}

        {/* Link Input and Copy Button */}
        {/* <div className="flex items-center gap-2">
          <input
            type="text"
            value={link}
            disabled
            className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600 cursor-not-allowed"
          />
          <button
            onClick={handleCopy}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "6px",
              background: isCopied ? "#007BFF" : "var(--color-grey-96, #F4F4F5)",
              color: isCopied ? "white" : "black",
              padding: "8px 16px",
              outline: "none",
              gap: "8px",
              transition: "background-color 0.3s ease, color 0.3s ease",
            }}
          >
            <FontAwesomeIcon icon={faLink} style={{ color: isCopied ? "white" : "black" }} />
            {isCopied ? "Copied!" : "Copy"}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ShareModal;
