import useModalStore from "zustand/zustandstore";

import { useEffect, useRef, useState } from "react";

import { Backdrop, CircularProgress } from "@mui/material";


import RecordRTC, { StereoAudioRecorder } from "recordrtc";


import CloseIcon from '@mui/icons-material/Close';
import api from "api/api";
import StreamModule from "./stream";
import ExitModal from "./exitmodal";
import ToastMessage from "utils/muialerts/righttoptoast";
import useAudioRecorder from "./audiorecorderhook";
import DeleteConfirmation from "./deleteconfirmation";
import { saveAudioInIndexDB } from "offlinestreaming/offlineBlobHandler";
const StreamingAudio = ({ setmessage, setseverity, setopen,}) => {
  const { setModalValue } = useModalStore();  
  const recorderRef = useRef(null);
  const[templates,setTemplate]=useState([])
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const mydata = useModalStore();
const[showdetailsmodal,setDetailsModal]=useState(true)
const [exitModal,setExitModal]=useState(false)
const[loading,setLoading]=useState(false)
const [streamui,setStreamui]=useState(false)
const [streamfilename,setStreamfilename]=useState('')
const [patientName, setPatientName] = useState('');
const [patientId, setPatientId] = useState('');
const [errorMessage, setErrorMessage] = useState('');
const [deleteconfirmationModal,setDeleteConfirmationModal]=useState(false)
const [user,setUser]=useState('')
const [connectionStatus, setConnectionStatus] = useState("Disconnected");
const [isRecording, setIsRecording] = useState(false);
const [isPaused, setIsPaused] = useState(false);
const [elapsedTime, setElapsedTime] = useState(0); // Total elapsed time in seconds
const startTimeRef = useRef(null); // Tracks the timer's start/resume time
const timerRef = useRef(null); // Reference to the timer interval
const audioStreamRef = useRef(null);
const [isError,setIsError]=useState(false)
const pausedref=useRef(false)
const blocker=useRef(false)
const wsRef = useRef(null);
const ismanualstop=useRef(false)
const ismanualrecordstart=useRef(false)
const token = sessionStorage.getItem("auth");
const onSaving = (blob) => {
    if (blob && blob.type.startsWith("audio/")) {   
      console.log("Valid audio blob:", blob);
      // Call the function to submit the audio blob
      if(blocker.current){
        return
      }
      console.log('submitting blob')
      submitAudioBlobManually(blob);
    } else {
      console.error("Invalid blob. Not an audio file.");
    }
 
};
const {  manualrecord, stopmanualRecording, pausemanualRecording , resumemanualRecording } = useAudioRecorder(onSaving);

const fetchmydata=async()=>{
  setLoading(true)
  try {
    const response = await api.get('/auth/me')
    if (response && response.templates && response.templates.default_template) {
      const data = await api.post('/v1/user_templates/get_all_templates', {
        practiceid: `${response.config.practiceid}`,
        departmentid: `${response.config.departmentid}`,
    });
  
    if(data.status==200){
       const allKeys = data?.file_names.map(item => Object.keys(item)[0]);
       setTemplate(allKeys)
    }
   
      setSelectedTemplate(response.templates.default_template)
      
    }
    if (response && response.templates && response.templates.template_names) {
      // setTemplate(response.templates.template_names);
      
  }
  } catch (error) {
    
  }finally{

    setLoading(false)
  }

}
useEffect(()=>{
fetchmydata()
},[])

const submitAudioBlobManually=async(blob)=>{

  try {
    const response = await api.get('/auth/me')
  const formData = new FormData();
  if(mydata.usertype=='BUSINESS'){
    formData.append('file', blob);
  formData.append('practiceid', response?.config.practiceid);
  formData.append('departmentid', response?.config.departmentid);
  formData.append('patientid', 12241);
  formData.append('notes',patientId);
  formData.append('tags',patientName);
  // formData.append('template_name', selectedTemplate.current);
  }else if(mydata.usertype=='PLUS'||mydata.usertype=='BASIC'){
    formData.append('file', blob);
  formData.append('practiceid', response?.config.practiceid);
  formData.append('departmentid', response?.config.departmentid);
  formData.append('patientid',patientId);
  formData.append('patientname', patientName);
  formData.append('template_name', selectedTemplate.current);
  
  }else{
    formData.append('file',blob);
    formData.append('practiceid', response?.config.practiceid);
    formData.append('departmentid', response?.config.departmentid);
    formData.append('patientid', patientId);
    formData.append('patientname', patientName);
   
  }
  const submitted = await api.post('v1/ehr/recording/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
if(submitted.status===200){
  setmessage('Your Recording is Successfully Completed!');
    setseverity('success'); // Change severity as needed
    setopen(true);
}else{

  saveAudioInIndexDB({
    file:blob, practiceid:response?.config.practiceid, departmentid:response?.config.departmentid
    , patientid:patientId, notes:'sample notes', tags:'testdata', 
    patientname:patientName, templatename:selectedTemplate.current, role:mydata.usertype, 
     filename:streamfilename,appointmentid:"",
 })

}
  } catch (error) {
    console.log(error)
  }finally{
  
    setIsError(false)
  setExitModal(false)
  setModalValue({ isStreamingModal: false })

  }
  

}

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
};

const startTimer = () => {
  startTimeRef.current = Date.now() - elapsedTime * 1000; // Adjust for any previously elapsed time
  timerRef.current = setInterval(() => {
    const timePassed = Math.floor((Date.now() - startTimeRef.current) / 1000);
    setElapsedTime(timePassed);
  }, 1000);
};

const stopTimer = () => {
  clearInterval(timerRef.current);
  timerRef.current = null;
};

const togglePause = () => {
  if (isPaused) {
    // Resume the timer
    pausedref.current=false
    if(ismanualrecordstart){
      resumemanualRecording()
    }

    startTimer();
  } else {
    // Pause the timer
    pausedref.current=true
    if(ismanualrecordstart){
       pausemanualRecording()
    }
   
    stopTimer();
  }
  setIsPaused((prev) => !prev);
};

const startRecording = async () => {
  pausedref.current = false;
  try {
    const filename = crypto.randomUUID();
    setStreamfilename(filename)
    const practiceid = user?.config?.practiceid || "";
    const departmentid = user?.config?.departmentid || "";
    
    const appointmentid = "";
       const isBusinessUser = mydata.usertype=='BUSINESS';
    const tags = isBusinessUser? patientName:"Sample tags";
    const patientname = patientName;

    const patientid = isBusinessUser?1000:patientId;
    const notes =isBusinessUser?patientId: "Sample notes";
  const ws = new WebSocket(
  `wss://api.prd.brainymed.com/bs/v1/recording/streaming?token=${token}&stream_filename=${filename}&practiceid=${practiceid}&departmentid=${departmentid}&patientid=${patientid}&appointmentid=${appointmentid}&notes=${encodeURIComponent(
    notes
  )}&tags=${encodeURIComponent(tags)}${
    !isBusinessUser ? `&patientname=${encodeURIComponent(patientName)}` : ""
  }${
    !isBusinessUser ? `&template_name=${encodeURIComponent(selectedTemplate)}` : ""
  }`
);

    wsRef.current = ws;

    ws.onopen = () => {
      setConnectionStatus("Connected");
      console.log("WebSocket connection established.");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    
    
      manualrecord()

    };

    ws.onclose = () => {
      if(!ismanualstop.current){
        ismanualrecordstart.current=true
        console.log('manual record initiated')
        manualrecord()


      }
      setConnectionStatus("Disconnected");
      console.log("WebSocket connection closed.");
    };

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    audioStreamRef.current = stream;

    const recorder = new RecordRTC(stream, {
      type: "audio",
      recorderType: StereoAudioRecorder,
      mimeType: "audio/webm",
      timeSlice: 5000, // Send data every 5 seconds
      desiredSampRate: 48000,
      numberOfAudioChannels: 1,
      ondataavailable: async (blob) => {
     
        if (ws.readyState === WebSocket.OPEN && !pausedref.current) {
          try {
          
            const base64Data = await blobToBase64(blob);
            ws.send(base64Data);
          } catch (error) {
            console.error("Error converting blob to base64:", error);
          }
        }
      },
    });

    recorder.startRecording();
    recorderRef.current = recorder;

    setElapsedTime(0);
    startTimer();
    setIsRecording(true);
    setIsPaused(false);
  } catch (error) {
    console.error("Error starting recording:", error);
    setConnectionStatus("Error");
  }
};

const stopRecording = () => {
  ismanualstop.current=true
  ismanualrecordstart.current=false
  if (recorderRef.current) {
    recorderRef.current.stopRecording(() => {
      recorderRef.current = null;
    });
  }

  if (audioStreamRef.current) {
    audioStreamRef.current.getTracks().forEach((track) => track.stop());
    audioStreamRef.current = null;
  }

  if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
    wsRef.current.close(1000, "Recording stopped by user");
  }

  stopTimer();
  setElapsedTime(0);
  setIsRecording(false);
  setIsPaused(false);
};

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(reader.result.split(",")[1]);
    };
    reader.onerror = (error) => reject(error);
  });
};


const handleStartRecording = () => {
 
  
    if(!patientName){
        setErrorMessage('enter patient name')
        return
    }
    if(!patientId){
        setErrorMessage('enter patient id ')
        return
    }
setDetailsModal(false)

startRecording()

setStreamui(true)
    
  };

const fetchuser=async()=>{
try {
    const response=await api.get('/auth/me')
    setUser(response)
} catch (error) {
    
}
}

useEffect(()=>{
if(mydata.isStreamingModal){
    fetchuser()
}
},[mydata])
const onExit=()=>{
  if(ismanualrecordstart.current){
    stopmanualRecording()
     stopRecording()
    setExitModal(false)
    setmessage('Your Recording is Successfully Completed!');
      setseverity('success'); // Change severity as needed
    setModalValue({ isStreamingModal: false })
    return
  }  
stopRecording()
setmessage('Your Recording is Successfully Completed!');
      setseverity('success'); // Change severity as needed
      setopen(true);
setExitModal(false)
setModalValue({ isStreamingModal: false })

}
const deleteStream=async()=>{

  try {
    setLoading(true)
    const response=await api.post(`/v1/recording/streaming/cancel?stream_filename=${streamfilename}`)
    if(response.status===200){
      if(ismanualrecordstart.current){
        blocker.current=true
        stopmanualRecording()
      }

      stopRecording()
      setExitModal(false)
      setDeleteConfirmationModal(false)
stopRecording()
setmessage('Your Recording is Successfully cancelled');
      setseverity('error'); // Change severity as needed
      setopen(true);

setModalValue({ isStreamingModal: false })

    }else{
      setDeleteConfirmationModal(false)
    }

  } catch (error) {
    console.log(error)
  }finally{
    blocker.current=false
setLoading(false)
  }
}



  return (
    <>
          {/* {showOverlay && (
        <BasicRecordingModule 
        updatePatientInfo={updatePatientInfo}
        submitAudio={submitAudio} 
        updateSelectedTemplate={updateSelectedTemplate}
        />
        
      )} */}
  
    {streamui?<StreamModule
    isError={isError}
    pausemanualRecording={pausemanualRecording}
    audioStreamRef={audioStreamRef}
    pausedref={pausedref}
    isPaused={isPaused}
    setIsPaused={setIsPaused}
    togglePause={togglePause}
    formatTime={formatTime}
    elapsedTime={elapsedTime}
    stopTimer={stopTimer}
    setExitModal={setExitModal}
    patientName={patientName}
    setDeleteConfirmationModal={  setDeleteConfirmationModal}
    />:<></>}
 {showdetailsmodal?  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 sm:p-6 min-h-[50vh] rounded-[15px] shadow-lg w-[90%] sm:w-[70%] max-w-md relative flex flex-col">
        <button
          onClick={() => {
            setPatientName('');
            setPatientId('');
            setModalValue({ isStreamingModal:false})
            setDetailsModal(false)

          }}
          className="absolute top-2 right-2 text-gray-600 hover:text-black"
        >
          <CloseIcon />
        </button>
        <div className="flex flex-col items-center mt-5 justify-between flex-grow">
          <div className="mb-4 text-center">
          {mydata.usertype === 'BUSINESS'?<> <div className="font-bold text-[#1C2A3A] text-lg sm:text-xl leading-7">Add Details</div></>:<div className="font-bold text-[#1C2A3A] text-lg sm:text-xl leading-7">Add Patient Details</div>
          }</div>

          {/* Patient Name Input */}
          {mydata.usertype === 'BUSINESS'?<>
            <div className=" mb-4 flex flex-col  w-[80%] mx-auto">
 <label className="text-start text-md w-full font-semibold mb-2">Name</label>
   <div className="">
         
            <input
              type="text"
              value={patientName}
              onChange={(e) => {
                if (errorMessage) setErrorMessage('');
                setPatientName(e.target.value);
              }}
              placeholder="Enter Conversation Name"
              className="w-full bg-transparent h-[45px] border border-gray-300 p-3  rounded-lg outline-none text-gray-800 text-sm font-normal"
            />
            {/* <img src={usericon} alt="User Icon" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" /> */}
          </div></div>
          <div className=" mb-4 w-[80%] flex flex-col mx-auto">
            <label className="text-start text-md w-full font-semibold mb-2">Notes</label>
            <textarea
              value={patientId}
              onChange={(e) => {
                if (errorMessage) setErrorMessage('');
                setPatientId(e.target.value);
              }}
              placeholder=""
              rows="6"
              className="w-full bg-transparent border border-gray-300 p-3  rounded-lg outline-none text-gray-800 text-sm font-normal resize-none"
            ></textarea>
        
          </div>
          
          </>:<><div className="relative mb-4 w-[80%] mx-auto">
            <input
              type="text"
              value={patientName}
              onChange={(e) => {
                if (errorMessage) setErrorMessage('');
                setPatientName(e.target.value);
              }}
              placeholder="Enter Patient Name"
              className="w-full bg-transparent h-[45px] border border-gray-300 p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
            />
          </div>

          {/* Patient ID Input */}
          <div className="relative mb-4 w-[80%] mx-auto">
            <input
              type="text"
              value={patientId}
              onChange={(e) => {
                const value = e.target.value;
                if (/[^0-9]/.test(value)) return; // Allow only numeric characters
                if (errorMessage) setErrorMessage('');
                setPatientId(value);
              }}
              placeholder="Enter Patient ID"
              className="w-full bg-transparent h-[45px] border border-gray-300 p-3 pl-10 rounded-lg outline-none text-gray-800 text-sm font-normal"
            />
          </div></>}
          {mydata.usertype === 'PLUS' && templates.length > 0 && (
  <div className="relative text-left mb-4 w-[80%] mx-auto">
    <select
      value={selectedTemplate}
      onChange={(e) => setSelectedTemplate(e.target.value)}
      className="w-full text-left bg-transparent h-[45px] border border-gray-300 p-3  rounded-lg outline-none text-gray-800 text-sm font-normal"
    >
        <option value="">Soap Note</option>
        <option value="HnP">History & Physical</option>
      {templates.map((template, index) => (
        <option className="text-left" key={index} value={template}>
          {template}
        </option>
      ))}
    </select>
  </div>
)}
{mydata.usertype === 'BASIC' ? (
  <div className="w-[80%] flex items-center gap-2">
    <select
     defaultValue="Soap Note" // Set the default value to "basic" if nothing is selected
      className="p-2 w-full border border-gray-300 rounded-lg text-sm font-medium text-gray-800 focus:outline-none"
      onChange={(e) => setSelectedTemplate(e.target.value)}  // Use onChange instead of onClick
    >
      <option value="">Soap Note</option>
      <option value="HnP">History & Physical</option>
    </select>
  </div>
) : (
  <></>
)}

          {/* Error Message */}
          {errorMessage && (
            <div className="text-red-500 text-xs sm:text-sm mb-4 w-[80%] mx-auto">
              {errorMessage}
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex items-center justify-center mt-4 gap-5 w-full">
            <button
              onClick={handleStartRecording}
              className="w-[40%] p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center"
            >
              Start Recording
            </button>
            <button
              onClick={() => {
                setPatientName('');
                setPatientId('');
                setModalValue({ isStreamingModal:false})
               setDetailsModal(false)
              }}
              className="w-[40%] p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>:<>

    </>}
 <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" size={40} />
      </Backdrop>
      <DeleteConfirmation savemodal={deleteconfirmationModal} setModalOpen={setDeleteConfirmationModal} onExit={deleteStream}/>
      <ExitModal savemodal={exitModal} setModalOpen={setExitModal} onExit={onExit}/>
      {/* <AddPatientmodal  isOpen={showpatientmodal} close={setPatientModal} /> */}
      {/* <AddStreamingDetails isOpen={showdetailsmodal} close={setDetailsModal}/> */}
    </>
  );
};

export default StreamingAudio;
