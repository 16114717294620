import { useState } from 'react';
import blinkscribelogo from '../../../assets/blinkscribe 4.svg';
import { Card, CardContent } from '@mui/material';
import Flag from 'react-world-flags';
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import api from 'api/api';
import ToastMessage from 'utils/muialerts/righttoptoast';
import arrowleft from '../../../assets/arrowleft.png'
const AccessNotes=()=>{
  const [error, setError] = useState("");
  const { type,token } = useParams()
  const [open, setOpen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const navigate=useNavigate('')
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };
  const countries = [
    { code: "US", prefix: "+1", label: "United States", value: "US", minLength: 10 },
    { code: "IN", prefix: "+91", label: "India", value: "IN", minLength: 10 },
    { code: "GB", prefix: "+44", label: "United Kingdom", value: "GB", minLength: 10 },
    { code: "DE", prefix: "+49", label: "Germany", value: "DE", minLength: 11 },
    { code: "FR", prefix: "+33", label: "France", value: "FR", minLength: 9 },
  ];
  const [useremail,setUserEmail]=useState('')
  const [otp, setOtp] = useState(new Array(4).fill(""));
    const [selectedCountry, setSelectedCountry] = useState(
      countries.find((country) => country.value === "US")
    );
    const [screen,setScreen]=useState(0)
    const [phoneNumber, setPhoneNumber] = useState("");
    const[isLoading,setIsLoading]=useState(false)
    const handlePhoneNumberChange = (e) => {
        if(error){
          setError('')
        }
        const value = e.target.value;
        const onlyNumbers = value.replace(/\D/g, ""); // Replace non-numeric characters
        setPhoneNumber(onlyNumbers);
      };
        // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      fontSize: "8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
  };
   const formatOptionLabel = ({ code, label }) => (
      <div className="flex items-center">
        <Flag
          code={code}
          className="mr-2"
          style={{ width: "20px", height: "15px" }}
        />
        <span>{label}</span>
      </div>
    );
    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;
    if(error){
      setError('')
    }
        let newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);
    
        // Move to next input if a number is entered
        if (element.nextSibling && element.value !== "") {
          element.nextSibling.focus();
        }
      };
      const handleKeyDown = (element, index) => {
        if (element.key === "Backspace") {
          if (otp[index] === "") {
            if (index > 0) {
              const updatedOtp = [...otp];
              updatedOtp[index - 1] = ""; // Clear the previous field
              setOtp(updatedOtp);
              element.target.previousSibling?.focus(); // Optionally focus the previous input field
            }
          } else {
            const updatedOtp = [...otp];
            updatedOtp[index] = ""; // Clear the current field
            setOtp(updatedOtp);
          }
        }
      };
      const sendemailotp = async () => {
        // Email validation regex pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
        if (!emailPattern.test(useremail)) {
          setSeverity("warning");
          setAlertMessage("Invalid email format. Please enter a valid email.");
          setOpen(true);
          return;
        }
      
        try {
          const response = await api.post('/v1/auth/doctor-instructions/email', {
            "encoded_string": token,
            "email": useremail,
          });
      
          if (response.status === 200) {
            setSeverity('success');
            setAlertMessage('OTP has been sent successfully.');
            setOpen(true);
            setScreen(1);
          } else {
            setSeverity("info");
            setAlertMessage(response.message || "Failed to send OTP.");
            setOpen(true);
           
          }
        } catch (error) {
          console.error(error);
          setSeverity("error");
          setAlertMessage("Something went wrong. Please try again.");
          setOpen(true);
        }
      };
      
      const sendmobileotp = async () => {
      
        if (phoneNumber.length < selectedCountry.minLength) {
 
          setSeverity("error");
          setAlertMessage(`Phone number should be at least ${selectedCountry.minLength} digits long.`);
          setOpen(true)
          return
        }
     
        try {
          const response = await api.post('/v1/auth/doctor-instructions/mobile', {
            "encoded_string": token,
            "country_code": selectedCountry.prefix,
  "mobilephonenumber": phoneNumber,
          });
      
          if (response.status === 200) {
            setSeverity('success');
            setAlertMessage('OTP has been sent successfully.');
            setOpen(true);
            setScreen(1);
          } else {
            setSeverity("info");
            setAlertMessage(response.message || "Failed to send OTP.");
            setOpen(true);
           
          }
        } catch (error) {
          console.error(error);
          setSeverity("error");
          setAlertMessage("Something went wrong. Please try again.");
          setOpen(true);
        }
      };

const submitotp=async()=>{
  try {
    let payload = {
      "encoded_string": token,
      "otp": otp.join(''),
    };
    if (type === "mobile") {
      payload.mobilephonenumber = phoneNumber;
      payload.country_code = selectedCountry.prefix;
    } else {
      payload.email = useremail;
    }
   const response=await api.post(`/v1/recording/get/doctor-instructions/${type}`,payload)
   if (response.status === 200) {
    const encodedNote = encodeURIComponent(response.data);
    navigate(`/view-note/${encodedNote}`);
  } else {
    setSeverity("info");
    setAlertMessage(response.message || "something went wrong");
    setOpen(true);
   
  }
} catch (error) {
  console.error(error);
  setSeverity("error");
  setAlertMessage("Something went wrong. Please try again.");
  setOpen(true);
}
}

    return(<>
        <div className="flex justify-around items-center min-h-screen h-auto p-0 md:p-6 sm:p-6">
       <Card className="w-full bg-white sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-lg md:max-w-2xl p-0 md:p-6 sm:p-6 shadow-none sm:shadow-lg md:shadow-lg md:bg-white sm:bg-white h-screen sm:h-[500px] md:h-[600px] sm:rounded-lg md:rounded-lg">
        <CardContent className="h-full">
       {screen===1? <div className="flex items-start ">
            <img
              onClick={() => {
             setScreen(0)
              
              }}
              src={arrowleft}
              alt="Down Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>:<></>}
          {type=='mobile'?
          <div className="flex flex-col items-center justify-around h-full">
          
            <div className="flex justify-center mt-10 md:mt-0 sm:mt-0">
              <img
                src={blinkscribelogo}
                alt="BlinkScribe Logo"
                className="h-24 sm:h-16 md:h-16 w-30 sm:w-auto md:w-auto"
              />
            </div>
            {/* Heading */}
        
            <div>
  <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#1c2a3a] text-2xl md:text-xl sm:text-xl tracking-[0] leading-9 whitespace-nowrap">
    {screen===0?<>Hello, User</>:<>One Time Passcode</>}
  </div>
  <div className="[font-family:'Inter-Regular',Helvetica] font-normal text-gray-500 text-[15px] tracking-[0] leading-[22.5px] whitespace-nowrap">
   {screen===0?<>Please enter your phone number to proceed with verification    <br />
    and view the doctor instructions.</>:<>
    Enter the code <br /> we just sent you to your phone number
   
   </>}
  </div>
</div>


      
            {screen===0?<div
              style={{ border: "1px solid #dcdcdc" }}
              className="flex flex-row w-[90%] md:w-[60%] sm:w-[60%] rounded-lg p-1 gap-2 md:h-auto "
            >
              <div className="md:w-[80px] w-[80px] h-[] ">
                <Select
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                  options={countries}
                  styles={customStyles}
                  formatOptionLabel={formatOptionLabel}
                />
              </div>
              <div className="flex-1">
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  placeholder="Enter Phone Number"
                  className="w-full h-[42px] md:h-[32px] bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal"
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              </div>
            </div>:<>
            <div className="flex justify-center gap-2 w-[90%] md:w-[70%] sm:w-[70%]">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="w-12 h-12 md:w-10 md:h-10 bg-transparent p-3 rounded-lg outline-none border border-gray-300 text-center text-gray-800 text-xl font-normal"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            
            </>}


            {/* Buttons */}
            <div className="flex flex-col items-center space-y-4 w-full mb-24 md:mb-0 sm:mb-0">
              <button
                className="flex w-[90%] md:w-[60%] sm:w-[60%] h-15 sm:h-10 md:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out"
          onClick={()=>{
            if(screen==1){

              submitotp()
              return
            }
            
            sendmobileotp()}}
              >
                <div className="font-semibold text-base">{screen===0?"Send Otp":"Submit"}</div>
              </button>
             
            </div>

           
        
            <div>
            
            </div>
          </div>:<>
           <div className="flex flex-col items-center justify-around h-full">
          
            <div className="flex justify-center mt-10 md:mt-0 sm:mt-0">
              <img
                src={blinkscribelogo}
                alt="BlinkScribe Logo"
                className="h-24 sm:h-16 md:h-16 w-30 sm:w-auto md:w-auto"
              />
            </div>
            {/* Heading */}
        
            <div>
  <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#1c2a3a] text-2xl md:text-xl sm:text-xl tracking-[0] leading-9 whitespace-nowrap">
    {screen===0?<>Hello, User</>:<>One Time Passcode</>}
  </div>
  <div className="[font-family:'Inter-Regular',Helvetica] font-normal text-gray-500 text-[15px] tracking-[0] leading-[22.5px] whitespace-nowrap">
   {screen===0?<>Please enter your email to proceed with verification
   <br />
    and view the doctor instructions.</>:<>
    Enter the code  we just sent you to your email
   
   </>}
  </div>
</div>


      
            {screen===0?
            
                <input
              type="email"
              placeholder="Enter Email"
           value={useremail}
             onChange={(e)=>{
setUserEmail(e.target.value)
             }}
              className="w-[80%] p-2  mb-2 focus-none"
            />
           
         
            
            :<>
            <div className="flex justify-center gap-2 w-[90%] md:w-[70%] sm:w-[70%]">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="w-12 h-12 md:w-10 md:h-10 bg-transparent p-3 rounded-lg outline-none border border-gray-300 text-center text-gray-800 text-xl font-normal"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            
            </>}

           <div className="flex flex-col items-center space-y-4 w-full mb-24 md:mb-0 sm:mb-0">
              <button
                className="flex w-[90%] md:w-[60%] sm:w-[60%] h-15 sm:h-10 md:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out"
          onClick={()=>{
            if(screen===1){
              submitotp()
              return
            }
            sendemailotp()
          }}
              >
                <div className="font-semibold text-base">{screen===0?"Send Otp":"Submit"}</div>
              </button>
             
            </div>

           
        
            <div>
            
            </div>
          </div>
          </>}
        </CardContent>
      </Card>
       </div>
     <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
    </>)
}
export default AccessNotes;