function formatToHtml(data) {
    let html = "";
    
    if (Array.isArray(data)) {
        html += "<div>";
        data.forEach(item => {
            html += `<p>${item}</p>`;
        });
        html += "</div>";
    } else if (typeof data === 'object' && data !== null) {
        html += "<div>";
        for (const [key, value] of Object.entries(data)) {
            html += `<h3>${key.replace(/_/g, ' ')}</h3>`;
            html += `<p>${value}</p>`;
        }
        html += "</div>";
    }
    
    return html;
}

export function formatToText(data) {
    if (typeof data === "string") {
      return data?.replace(/\n/g, "<br/>") // Return the string as is
    }
  
    let textOutput = "";
  
    if (Array.isArray(data)) {
        data.forEach((item) => {
          textOutput += `• ${item}\n\n`;
        });
      } else if (typeof data === "object" && data !== null) {
      for (const [key, value] of Object.entries(data)) {
        textOutput += `<b>${key.replace(/_/g, " ")}:</b>\n${value}\n\n`;
      }
    }
  
    return textOutput;
  }
  