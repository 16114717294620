import { useParams } from "react-router-dom";

const ViewNote = () => {
  const { note } = useParams(); // Get the encoded note from URL
  const decodedNote = decodeURIComponent(note); // Decode the note

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-lg rounded-2xl p-6 w-full max-w-lg">
        <h2 className="text-xl font-semibold text-gray-800 border-b pb-3 mb-4">
          Doctor's Instructions
        </h2>
        <pre className="text-gray-700 whitespace-pre-wrap bg-gray-50 p-4 rounded-md">
          {decodedNote}
        </pre>
      </div>
    </div>
  );
};

export default ViewNote;
