
import redmic from '../../assets/redmic.png'
import calendarIcon from '../../assets/calendar-record.png'
import greentick from '../../assets/green-tick.png'
import editrecord from '../../assets/editrecord.png'
import pdf from '../../assets/pdf.png'
import dummypdf from '../../assets/svgs/dummypdf.pdf'
import ArchiveIcon from '@mui/icons-material/Archive';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import EditNoteIcon from "@mui/icons-material/EditNote";
import FolderIcon from '@mui/icons-material/Folder';
import { formatDate } from 'utils/date'
import ConstentAcceptance from "alerts/recordingscreen/consentacceptance";
import api from '../../api/api'
import PdfRender from './pdfrender'
import ToastMessage from 'utils/muialerts/righttoptoast'
import Ring from 'pages/landing/ring'
import { FaInfoCircle } from 'react-icons/fa'
import SimpleModal from 'alerts/recordingscreen/simplemodal'
import useModalStore from 'zustand/zustandstore'
import PromptModal from '../../alerts/recordingscreen/promptmodal'
import StreamingCombineToastModal from 'alerts/recordingscreen/streamingcombine'
import { Backdrop, CircularProgress } from '@mui/material'
const InitialRecording=({setIsRecording,patient_id,  FetchRecorderData,setAppointments,pagedata,startRecording})=>{
  const [ispdfshow,setIspdfShow]=useState(false)
    const { id } = useParams(); 
    const [consentModal,setConsentModal]=useState(false)
   const [latestapp,setLatestApp]=useState({})
  const[showinfo,setShowInfo]=useState(false)
  const[isConsentloading,setConcentLoading]=useState(false)
  const [streamingcombine,setStreamingCombine]=useState(false)
  const [currentobj,setCurrentObj]=useState('')
  const{setModalValue }=useModalStore()
  const [promptmodal,setPromptModal]=useState(false)
  const[loading,setIsLoading]=useState(false)
  const[apploading,setAppoloading]=useState(false)
  const[data,setData]=useState([])
  const [pdffile,setPdffile]=useState()
  const navigate =useNavigate()
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const location = useLocation();
  const mydata = useModalStore();
  const {ts} = location.state
  const isBackToRecord = sessionStorage.getItem("isbacktorecord");
const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
};

  const fetchdata= async()=>{
    setIsLoading(true)
    try {
      let response =await api.post(`/v1/patient/${patient_id}/recording/details`,{
        "filter_array": {
      
          "date_filter": {
           
          }
        },
      
      })
        if(response.status==200){
     setData(response?.data)
   //  setAppointments(response?.data)

      }else if(response.status==201){
        setData([])
      }
    } catch (error) {
      console.log(error)
    }finally{
      setIsLoading(false)
      
    }
  }
  const loadData= async()=>{
    if(!patient_id){
      return
    }
    try {
      let response =await api.post(`/v1/patient/${patient_id}/recording/details`,{
        "filter_array": {
      
          "date_filter": {
           
          }
        },
      
      })
        if(response.status==200){
     setData(response?.data)
   //  setAppointments(response?.data)

      }else if(response.status==201){
        setData([])
      }
    } catch (error) {
      console.log(error)
    }finally{
           
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if (data.length > 0) {
            if (data[0].transcriptionstatus!=='Processing') {
          clearInterval(interval); // Stop polling if all items are approved
          console.log('All items are approved.');
        } else {
          loadData(); // Continue calling the API if not all are approved
        }
      } else {
        loadData(); // Fetch data initially if the array is empty
      }
    }, 2000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [data]);
  const handlepdf = async (value) => {
if(data[0].transcriptionstatus=='Processing'){
  setMessage('Update in process. Hold on!');
  setSeverity('info'); // Change severity as needed
  setOpen(true);
  return
}

    try {
      // Trigger loading spinner
      setIsLoading(true);
      const uniqueParam = new Date().getTime(); // or use a random number: Math.random()

      // Fetch the PDF from the server with a unique parameter to prevent caching
      const response = await api.get(`/v1/documents/PDF/${value}?_=${uniqueParam}`, {
        responseType: "blob", // Ensure response type is 'blob' for binary data
      });
      // Fetch the PDF from the server
      // const response = await api.get(`/v1/documents/PDF/${value}`, {
      //   responseType: 'blob', // Ensure response type is 'blob' for binary data
      // }); 
        // Create a Blob from the PDF data
        const pdfBlob = new Blob([response], { type: 'application/pdf' });
  
        // Generate a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdffile(pdfBlob)
        // Open the generated URL in a new tab
       // window.open(pdfUrl, '_blank');
    
    } catch (error) {
      console.error('Error fetching the PDF:', error);
    } finally {
      // Turn off loading spinner
      setIsLoading(false);
     setIspdfShow(true)
    }
  };
const startStreaming=async()=>{
  // setModalValue({ 
  //   filename:latestAppointment.audiolink,
  //   rId:latestAppointment.id,
  //   patName:latestAppointment.patient_name,

  // })   
  try {
    setAppoloading(true)
    const response =await FetchRecorderData()
setAppoloading(false)
    if(response[1] && Array.isArray(response[1]) &&response[1].length){    
    
      const currentappontments=response[1].filter((x)=>x.appointmentid==id) 

      if(!currentappontments.length){   
        
 startRecording({combine:"new",appointment_id:id,patient_id:pagedata?.patientid,patient_name:pagedata?.patient_name,department_id:pagedata?.departmentid,practice_id:pagedata?.practiceid}) 
  setModalValue({recordtime:'00:00'})        
  setIsRecording(true)
        return 
      }
      const getLatestAppointment = (appointments) => {
        return appointments.reduce((latest, appointment) => {
            return new Date(appointment.ts) > new Date(latest.ts) ? appointment : latest;
        });
    };
    const latestAppointment = getLatestAppointment(currentappontments);
    const appointmentDate = new Date(latestAppointment.ts);
    const today = new Date();
    
    // Reset the time part for comparison
    appointmentDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    // if (appointmentDate.getTime() === today.getTime()) {
     if (true) {
      setLatestApp(latestAppointment)
 setStreamingCombine(true)
  } 


  }else{    
  startRecording({combine:"new",appointment_id:id,patient_id:pagedata?.patientid,patient_name:pagedata?.patient_name,department_id:pagedata?.departmentid,practice_id:pagedata?.practiceid}) 
  setModalValue({recordtime:'00:00'})
  setIsRecording(true)
};
  } catch (error) {
    console.log(error)
    setMessage('Looks like something went wrong. Please try again.');
    setSeverity('info'); // Change severity as needed
    setOpen(true);
  }finally{
    setAppoloading(false)

  }
}
const handleCombine = (type) => {
  const recordingParams = {
    combine: type,
    appointment_id: id,
    patient_id: pagedata?.patientid,
    patient_name: pagedata?.patient_name,
    department_id: pagedata?.departmentid,
    practice_id: pagedata?.practiceid,
  };

  if (type === "combine") {
    recordingParams.filename = latestapp?.audiolink;
    recordingParams.rowid = latestapp?.id;
  }

  startRecording(recordingParams);
  setModalValue({ recordtime: "00:00" });
  setIsRecording(true);
};

const handlestartrecording=()=>{
  const currentDate = new Date();
  const timestampDate = new Date(ts);
  const currentDateMidnight = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const timestampDateMidnight = new Date(timestampDate.getFullYear(), timestampDate.getMonth(), timestampDate.getDate());
if (timestampDateMidnight > currentDateMidnight) {
setMessage('Recording for future dates is not allowed.');
setSeverity('info'); // Change severity as needed
setOpen(true);
  } else {
if(mydata.isOpen){
setMessage('Recording already in progress');
setSeverity('info'); // Change severity as needed
setOpen(true);
return
}
if(mydata.isStreaming){

// startRecording({appointment_id:data[0]?.appointmentid,patient_id:pagedata?.patientid,patient_name:pagedata?.patient_name,department_id:pagedata?.departmentid,practice_id:pagedata?.practiceid}) 
// setModalValue({recordtime:'00:00'})
startStreaming()
return

}
    setIsRecording(true)
  }

}
const handleAcceptance=async()=>{
  setConcentLoading(true)
  try {
    const response =await api.put(`/v1/patients/consent-update`,{
      "patient_id": pagedata?.patientid,
  "consent_to_record": true
    })
    if(response.status===200){
setConsentModal(false)
handlestartrecording()
    }else{
      setConsentModal(false)
    }
  } catch (error) {
    console.log(error)
  }finally{

setConcentLoading(false)
  }
}
const checkConsent=async()=>{
  try {
    setConcentLoading(true)
    const user = await api.get('/auth/me');   
if(user?.config?.consent_required==0){
  handlestartrecording()
return
}

    const response=await api.get(`/v1/patients/get/${pagedata?.patientid}`)
    if(response.status==200){
      if(response?.data.consent_to_record==1){
        handlestartrecording()
      }else{
        setConsentModal(true)
      }
    }else{
      setConsentModal(true)
    }
  } catch (error) {
    
  }finally{
setConcentLoading(false)
  }
}

  useEffect(()=>{
    if(patient_id){
       fetchdata()
    } 
  },[patient_id])
  
    return(<>
 <div className="mt-[6%] gap-5 flex flex-col justify-center items-center">
 <div className="[font-family:'Poppins-SemiBold',Helvetica] font-semibold text-[#575860] text-sm text-center tracking-[0] leading-[normal]">
        Start Recording
      </div>
      <img
      alt=''
  onClick={() => {
    // handlestartrecording()
    checkConsent()
  }}
  src={redmic}
  className='w-[80px] h-[80px] cursor-pointer'
/>
 </div>
 {!loading&&(data.length?
 <div className='flex w-full gap-5 flex-col mt-[5%] items-center justify-center'>
      <div className="[font-family:'Poppins-Regular',Helvetica] font-normal text-black text-sm tracking-[0] leading-[normal] whitespace-nowrap">
        Previous Note
      </div>
      <div
       onClick={(e) => {
        e.stopPropagation()
        if (data[0].transcriptionstatus === 'Processing') {
          setMessage('Update in process. Hold on!');
          setSeverity('info'); // Change severity as needed
          setOpen(true);
          return;
        }else{
            // handlepdf(data[0].audiolink.split('.')[0])
            navigate(`/archive-edit/${data[0]?.id}/${data[0]?.patient_name}`)

        }
   
      }}
      className="flex items-center justify-between p-4 cursor-pointer bg-white shadow-md rounded-[10px] w-auto min-w-[240px] h-10 relative" style={{ boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)" }}>


  <div className='flex items-end relative'>
    <img src={calendarIcon} alt="Calendar Icon" className="w-5 h-5" />
    <img src={greentick} alt="Green Tick Icon" className="w-3 h-2 relative right-1 bottom-1" />
  </div> 

  <div style={{ cursor: "pointer" }} className="[font-family:'Poppins-Regular',Helvetica] font-normal text-[#262629] text-[13px] tracking-[0] leading-[normal]">
    {formatDate(data[0].ts)} 
  </div>
{/* 
 {
 data[0].transcriptionstatus !== 'Processing' ?<div
    onClick={() => {
      if (data[0].transcriptionstatus === 'Processing') {
        setMessage('Update in process. Hold on!');
        setSeverity('info'); // Change severity as needed
        setOpen(true);
        return;
      }
      navigate(`/archive-edit/${data[0].id}/${data[0].patient_name}`);
    }}
    style={{ cursor: "pointer" }}
  >
    <img src={editrecord} alt="Edit Icon" className="w-5 h-5 cursor-pointer" />
  </div>:<>{data[0].transcriptionstatus === 'Processing' &&(<div className="w-7 h-7">
    {
     <div className="w-7 h-7">
            <Ring value={Math.floor(data[0].handling_time)} />
          </div> 
          }
    </div>)}</>} */}

    {
 (data[0].transcriptionstatus === 'APPROVED'||data[0]?.emr_posted_status )? (
   (data[0].transcriptionstatus === 'APPROVED'|| (data[0]?.handling_time === 100.0 && data[0].transcriptionstatus === 'Summarized')) ? (
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowInfo(true);
        }}
      >
        {
          Object.values(data[0].emr_posted_status).every(status => status === true) ? (
            <FaInfoCircle className="text-green-500 w-5 h-5 cursor-pointer" />
          ) : (
            <FaInfoCircle className="text-red-500 w-5 h-5 cursor-pointer" />
          )
        }
        {/* <img src={pdf} onClick={() => handlepdf(data[0].audiolink.split('.')[0])} alt="PDF Icon" className="w-5 h-5 cursor-pointer" /> */}
      </div>
    ) : (
      <>
        {data[0].transcriptionstatus === 'Processing' && (
          <div className="w-7 h-7">
            <Ring value={Math.floor(data[0].handling_time)} />
          </div>
        )}
      </>
    )
  ) : (
    <div className="w-5 h-5"></div>
  )
}
{/* <FormatColorTextIcon
  onClick={(e) => {  
    e.stopPropagation();
setCurrentObj(data[0])
setPromptModal(true)
  }}
/> */}
</div> 


 </div>: <div className='flex gap-5 flex-col mt-[5%] items-center justify-center'>

   
 </div>)
}
 <div className='flex gap-5  mt-[5%] items-center justify-center'>
      <div onClick={() => {
  const timestamp = new Date().getTime();
  const profilePhotoUrl = pagedata.profile_photo 
    ? `${pagedata.profile_photo}?t=${timestamp}&extraText=arc` 
    : null;

  navigate(`/archive/${patient_id}`, { state: { ts: timestamp, profile_photo: profilePhotoUrl } });
}}
 class="flex items-center gap-2 justify-around p-4 bg-[#F8F8F8] shadow-md rounded-[20px] w-auto h-10" style={{boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)"}}>
      <FolderIcon style={{cursor:'pointer'}}/>
      <div className="[font-family:'Poppins-Regular',Helvetica] cursor-pointer font-normal text-[#000000] text-[14px] tracking-[0] leading-[normal] whitespace-nowrap">
   Archive
      </div>
   
</div>

 </div>
 <SimpleModal isModalOpen={showinfo} setModalOpen={setShowInfo} data={data[0]}/>
 <PromptModal isModalOpen={promptmodal} setModalOpen={setPromptModal} data={currentobj}/>
 <ToastMessage
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
            />
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={apploading||isConsentloading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>        
 <PdfRender ispdfshow={ispdfshow}setIspdfShow={setIspdfShow} pdfBlob={pdffile} data={data[0]}/>
 <StreamingCombineToastModal isModalOpen={streamingcombine} handlecombine={handleCombine} />
 {consentModal?<ConstentAcceptance 
handleAcceptance={handleAcceptance}
 ismodal={consentModal} setModal={setConsentModal}/>:<></>}
    </>)
}
export default InitialRecording;