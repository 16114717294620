import React, { useState } from 'react';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';

const Soucket = () => {
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [ws, setWs] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const token = sessionStorage.getItem("auth");
    const handleDataAvailable = (event) => {
        if (event && event.data && event.data.size > 0) {
            console.log("Audio data available:", event.data);  // Log raw audio data
            blobToBase64(event.data).then(b64 => {
                console.log("Base64 Audio Data:", b64);  // Log the base64 audio data
                if (ws && ws.readyState === WebSocket.OPEN) {
                    console.log("Sending data to WebSocket..."); // Log that data is being sent
                    ws.send(b64); // Send the base64-encoded audio data to the WebSocket server
                } else {
                    console.log("WebSocket is not open. Current state:", ws?.readyState);
                }
            }).catch((error) => {
                console.error('Error converting blob to base64:', error);
            });
        }
    };
    

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
        });
    };

    const startStreaming = () => {
        const filename = crypto.randomUUID();
        const practiceid = 7672032439;
        const departmentid = "1";
        const patientid = "233543436";
        const appointmentid = "";
        const notes = "Sample notes";
        const tags = "Sample tags";
        const patientname = "plusrecording";
  
        const newWs = new WebSocket(
          `wss://api.prd.brainymed.com/bs/v1/recording/streaming?token=${token}&stream_filename=${filename}&practiceid=${practiceid}&departmentid=${departmentid}&patientid=${patientid}&appointmentid=${appointmentid}&notes=${encodeURIComponent(
            notes
          )}&tags=${encodeURIComponent(tags)}&patientname=${encodeURIComponent(patientname)}`
        );

        newWs.onopen = () => {
            console.log('WebSocket connection opened');
            setWs(newWs);
            startRecording(newWs);
        };

        newWs.onclose = () => {
            console.log('WebSocket connection closed');
        };

        newWs.onmessage = (event) => {
            console.log('Received from server:', event.data);
            document.getElementById('responseContainer').innerHTML += `<p>${event.data}</p>`;
        };

        newWs.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    };

    const startRecording = (wsInstance) => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                const recorder = new RecordRTC(stream, {
                    type: 'audio',
                    recorderType: StereoAudioRecorder,
                    mimeType: 'audio/webm',
                    timeSlice: 5000, // Sends audio every 5 seconds
                    desiredSampRate: 48000,
                    numberOfAudioChannels: 1,
                    ondataavailable: handleDataAvailable
                });

                setMediaRecorder(recorder);
                setIsRecording(true);
                recorder.startRecording();
            })
            .catch((error) => {
                console.error('Microphone access denied:', error);
            });
    };

    const stopStreaming = () => {
        if (mediaRecorder) {
            mediaRecorder.stopRecording();
            setIsRecording(false);
        }
        if (ws) {
            ws.close();
            setWs(null);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-black text-green-400 p-4">
            <h1 className="text-3xl font-bold mb-6">Realtime WebSocket Audio Streaming</h1>
            
            <div className="flex gap-4">
                {!isRecording ? (
                    <button
                        className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded-lg transition-all"
                        onClick={startStreaming}
                    >
                        Start Streaming
                    </button>
                ) : (
                    <button
                        className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-lg transition-all"
                        onClick={stopStreaming}
                    >
                        Stop Streaming
                    </button>
                )}
            </div>

            <div id="responseContainer" className="mt-6 p-4 w-full max-w-lg bg-gray-900 rounded-lg text-white">
                <h2 className="text-lg font-semibold">Server Responses:</h2>
            </div>
        </div>
    );
};

export default Soucket;
