export function formatDate(dateStr) {
  const dateObj = new Date(dateStr);
  return dateObj.toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  });
}

  export function formatCurrentDate() {
    const options = { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    };
    const date = new Date();
    return date.toLocaleDateString('en-US', options);
}
export function formatDateToYMD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading 0 if needed
  return `${year}-${month}-${day}`;
}

//Thursday, September 5, 2024
export const decodeTimeToISO = (minutes) => {
  // Get hours and minutes from total minutes
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  // Set seconds and milliseconds to 0
  const seconds = 0;
  const milliseconds = 0;

  // Format hours and minutes to always have two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = mins.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  const formattedMilliseconds = milliseconds.toString().padStart(3, '0');

  // Return the formatted time in the "HH:MM:SS.mmmZ" format
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}Z`;
};
export function currentformatedDate() {
  const today = new Date();
    
    // Options for formatting the date and time
    const options = { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: false, 
        timeZone: 'Asia/Kolkata' 
    };

    // Create the formatted date string
    const dateString = today.toLocaleString('en-US', options).replace(',', '');
    
    // Get the timezone offset
    const timezoneOffset = today.getTimezoneOffset();
    const hoursOffset = String(Math.abs(Math.floor(timezoneOffset / 60))).padStart(2, '0');
    const minutesOffset = String(Math.abs(timezoneOffset % 60)).padStart(2, '0');
    const gmtOffset = `GMT${timezoneOffset <= 0 ? '+' : '-'}${hoursOffset}${minutesOffset}`;

    // Fixed timezone name for India Standard Time
    const timezoneName = 'India Standard Time';

    return `${dateString} ${gmtOffset} (${timezoneName})`;
}

//input 2024-10-29T08:40:16
//output Mon, Oct 14, 2024"
export function formatTimestampToDDMMDtYYYY(timestamp) {
  // Try to create a new Date object from the timestamp
  const date = new Date(timestamp);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return ''; // Return an empty string if the timestamp is invalid
  }

  // Define an options object for date formatting
  const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };

  // Format the date to the desired format
  return date.toLocaleDateString('en-US', options);
}


export function formatappdate(inputDate) {
  const date = new Date(inputDate);
  
  // Check if the date is valid
  if (isNaN(date.getTime())) {
      return new Date().toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
      });
  }

  return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
  });
}

// // Example usage
// console.log(formatDate("2025-02-09"));  // Sunday, February 9, 2025
// console.log(formatDate("invalid-date"));  // Current date in the same format
