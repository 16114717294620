import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, useParams, matchPath, useNavigate } from 'react-router-dom';
import MainHeader from './header/header';
import { routes } from './routes/routes';
import './App.css';
import PageNotAvailable from 'error/pagenotfound';
import AudioOverlay from 'audiopopup/audiooverlay';
import useModalStore from 'zustand/zustandstore';
import ToastMessage from 'utils/muialerts/righttoptoast';
import { submitmyaudio } from 'audiohandlers/audiosubmissions';
import { Backdrop, CircularProgress } from '@mui/material';
import CombineToastModal from 'alerts/recordingscreen/combineToast';
import { Combinemyaudio } from 'audiohandlers/audiosubmissions';
import BasicRecordingWrap from 'basic/recordingoverlay/recordingwrap';
import api from 'api/api';
import StreamingAudio from 'streaming/streamingaudio';

import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import useAudioRecorder from 'streaming/audiorecorderhook';
import DeleteConfirmation from 'streaming/deleteconfirmation';

const App = () => {
  return (
    <div className="App">
      <Router basename="/">
        <AppContent />
      </Router>
    </div>
  );
};

function AppContent() {
  const accessToken = sessionStorage.getItem('auth');
  const location = useLocation();
  const { id, patientid } = useParams();
  const currentRoute = routes.find(route => matchPath(route.path, location.pathname));
  const showHeader = currentRoute && currentRoute.showHeader;
  // const currentRoute = routes.find(route => route.path === location.pathname);

  const [deleteconfirmationModal,setDeleteConfirmationModal]=useState(false)
  const mydata = useModalStore();
  const [allow, setallow] = useState(true)
  const { setModalValue } = useModalStore()
  const [open, setOpen] = useState(false);
  const [isloading, setIsloading] = useState(false)
  const [combineaudio, setCombineAudio] = useState(false)
  const [audioFile, setAudiofile] = useState('')
  const [message, setMessage] = useState('');
  const [isError,setIsError]=useState(false)
  const [severity, setSeverity] = useState('success');
  const [isUserTypeLoading, setIsUserTypeLoading] = useState(true);
  const unrestrictedRoutes = ["/auth",'/payment','/doctor-instructions','testing/id/token','view-note', '/login', '/onboarding','/sign-up', "/updatepassword", "/forgotpassword", '/record', '/auth1', '/verify'];
  const recorderRef = useRef(null);
  const[combineStatus,setCombineStatus]=useState(false)
const audioStreamRef = useRef(null);
const[streamingfilename,setStreamingfilename]=useState('')
const pausedref=useRef(false)
const navigate=useNavigate()
const wsRef = useRef(null);
const token = sessionStorage.getItem("auth");

const onSaving = (blob) => {
 
    if (blob && blob.type.startsWith("audio/")) {    
      if(combineStatus){
  
     
        Combinemyaudio(blob, mydata)
      } else{
        
             submitmyaudio(blob, mydata)
      }

    } else {
      console.error("Invalid blob. Not an audio file.");
    }
 
}
const {  manualrecord, stopmanualRecording, pausemanualRecording , resumemanualRecording } = useAudioRecorder(onSaving);
const deleteStream=async()=>{

  try {
    setIsloading(true)
    const response=await api.post(`/v1/recording/streaming/cancel?stream_filename=${streamingfilename}`)
    if(response.status===200){
         setModalValue({recordtime:'00:00'})
      sessionStorage.removeItem('audioBlob');
      setDeleteConfirmationModal(false)
      stopRecording()
setModalValue({ isStreamingModal: false })
setModalValue({recordtime:'00:00'})
setModalValue({overlaypausetime:'00:00'})        
setModalValue({overlayispaused:false})   
sessionStorage.removeItem('audioBlob');
setModalValue({ isCancel: true,isOpen: false })
    }else{
      setDeleteConfirmationModal(false)
    }
    setTimeout(() => {
      navigate("/landing");
    }, 1500);
  } catch (error) {
    console.log(error)
  }finally{  
setIsloading(false)
  }
}
const startRecording = async ({combine,patient_id,appointment_id,patient_name,department_id,practice_id,filename,rowid}) => {
  pausedref.current=false
  try {
    const filenamee = crypto.randomUUID();
    setStreamingfilename(filenamee)
    const practiceid = practice_id;
    const departmentid = department_id;
    const patientid = patient_id;
    const appointmentid = appointment_id;
    const notes = "Sample notes";
    const tags = "Sample tags";
    const patientname = patient_name;
    let queryString = `token=${token}&stream_filename=${filenamee}&practiceid=${practiceid}&departmentid=${departmentid}&patientid=${patientid}&appointmentid=${appointmentid}&notes=${encodeURIComponent(notes)}&tags=${encodeURIComponent(tags)}&patientname=${encodeURIComponent(patientname)}`;

    if (combine === "combine") {
      queryString += `&category=combine&filename=${filename}&rowid=${rowid}`;
      setCombineStatus(true)
    }else{
      setCombineStatus(false)
    }
    const ws = new WebSocket(`wss://api.prd.brainymed.com/bs/v1/recording/streaming?${queryString}`);

    wsRef.current = ws;

    ws.onopen = () => {
 
      console.log("WebSocket connection established.");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      // setIsError(true)
      // manualrecord()
    };

    ws.onclose = () => {
  
      console.log("WebSocket connection closed.");
    };


    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    audioStreamRef.current = stream;

    const recorder = new RecordRTC(stream, {
      type: "audio",
      recorderType: StereoAudioRecorder,
      mimeType: "audio/webm",
      timeSlice: 5000, // Send data every 5 seconds
      desiredSampRate: 48000,
      numberOfAudioChannels: 1,
      ondataavailable: async (blob) => {
        if (ws.readyState === WebSocket.OPEN && !pausedref.current) {
          try {
            const base64Data = await blobToBase64(blob);
            ws.send(base64Data);
          } catch (error) {
            console.error("Error converting blob to base64:", error);
          }
        }
      },
    });

    recorder.startRecording();
    recorderRef.current = recorder;

   
 
  } catch (error) {
    console.error("Error starting recording:", error);
  
  }
};
const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(reader.result.split(",")[1]);
    };
    reader.onerror = (error) => reject(error);
  });
};
const stopRecording = () => {
  // if(isError){
  //    stopmanualRecording()
  // } 
  if (recorderRef.current) {
    recorderRef.current.stopRecording(() => {
      recorderRef.current = null;
    });
  }

  if (audioStreamRef.current) {
    audioStreamRef.current.getTracks().forEach((track) => track.stop());
    audioStreamRef.current = null;
  }

  if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
    wsRef.current.close(1000, "Recording stopped by user");
  }

};



  const fetchuser = async () => {
    try {
      setIsUserTypeLoading(true)
      const response = await api.get('/auth/me')
      if (response && response.config && response.config.user_type) {

        if (response.config.user_type === 'BASIC') {

          setModalValue({ usertype: 'BASIC' })

        } else if (response.config.user_type === 'PRO') {

          setModalValue({ usertype: 'PRO', })

        }else if (response.config.user_type === 'BUSINESS') {

          setModalValue({ usertype: 'BUSINESS', })

        } else {
          setModalValue({ usertype: 'PLUS', })
        }

      }
    } catch (error) {

    } finally {
      setIsUserTypeLoading(false)
    }

  }
  useEffect(() => { 
   
    if (!location.pathname.includes("/doctor-instructions")&&!location.pathname.includes("/payment")&&!location.pathname.includes("/view-note")&&!unrestrictedRoutes.includes(location.pathname)) {
      fetchuser()
    } else {
      setIsUserTypeLoading(false)
    }

  }, [])
  if (isUserTypeLoading) {
    
    return (
      <div className='bg-white h-auto min-h-[100vh]  [background:linear-gradient(180deg,rgb(255,255,255)_20%,rgb(162,198,253)_100%)]'>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>  </div>
    );


  }
  if (!location.pathname.includes("/doctor-instructions")&&!location.pathname.includes("/view-note")&&!location.pathname.includes("/payment")&&(!unrestrictedRoutes.includes(location.pathname) && !accessToken)) {
    // location.pathname.includes("/testing")
      return <Navigate to="/auth1" />;
  }
  const retrieveAudioBlob = () => {
    const base64String = sessionStorage.getItem('audioBlob');
    if (base64String) {
      // Extract the base64 data from the string
      const byteString = atob(base64String.split(',')[1]);
      // Get the MIME type from the base64 string
      const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];

      // Create a Uint8Array to hold the binary data
      const ab = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ab[i] = byteString.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const audioBlob = new Blob([ab], { type: mimeString });

      console.log('Retrieved audio Blob:', audioBlob);
      return audioBlob;
    } else {
      console.log('No audio Blob found in session storage.');
      return null;
    }
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const ContinueSubmit = async (combinedAudioBlob, state) => {
    try {
      setIsloading(true);
      let response = null;
      if (state === 'new') {
        response = await submitmyaudio(combinedAudioBlob, mydata);
      } else if (state === 'combine') {
        response = await Combinemyaudio(combinedAudioBlob, mydata);
      }
      if (response && response.status === 200) {
        setMessage('Your Recording is Successfully Completed!');
        setSeverity('info');
        setOpen(true);
      } else {
        setMessage('Something Went Wrong');
        setSeverity('warning');
        setOpen(true);
      }

    } catch (error) {

      setMessage('Something Went Wrong');
      setSeverity('error');
      setOpen(true);
    } finally {
      sessionStorage.removeItem('audioBlob');

      setIsloading(false);
      setModalValue({ isCombine: false });
      setCombineAudio(false)
    }
  };

  const handlecombine = async (toggle) => {
    if (toggle == 'new') {
      ContinueSubmit(audioFile, 'new')
    } else if (toggle == 'combine') {
      ContinueSubmit(audioFile, 'combine')
    }

  }
  const isBackToRecord = sessionStorage.getItem("isbacktorecord");

  const Audiocollecter = async (x, y) => {
    if (isBackToRecord == 'a' || (!y.current)) {
      const combinedAudioBlob = new Blob([retrieveAudioBlob(), x], { type: 'audio/webm' });

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        sessionStorage.setItem('audioBlob', base64data);
      };
      reader.readAsDataURL(combinedAudioBlob);
      setCombineAudio(false)
      return
    }
    const mydat = useModalStore.getState();
    if (mydat.isCancel) {
      return
    }
    if (!y.current) {
      return
    }
    const combinedAudioBlob = new Blob([retrieveAudioBlob(), x], { type: 'audio/webm' });
    if (mydata.isCombine) {
      setAudiofile(combinedAudioBlob)
      setCombineAudio(true)
    } else {
      ContinueSubmit(combinedAudioBlob, 'new')
    }


  }
  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.path) {
  //       return <Route exact path={route.path} element={<route.component />} key={route.path} />;
  //     }
  //     return null;
  //   });
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.path) {
        // Check if the current route is unrestricted or if the user's type is allowed
      
        const isUnrestricted = unrestrictedRoutes.includes(route.path);

        const isUserAllowed = route.allowedTypes?.includes(mydata.usertype);

        // If it's an unrestricted route or the user is allowed, render the route
        if (isUnrestricted || isUserAllowed||location.pathname.includes("/payment") ||location.pathname.includes("/doctor-instructions")||location.pathname.includes("/view-note")) {
          return <Route exact path={route.path} element={<route.component startRecording={startRecording} 
          stopRecording={stopRecording}
          pausedref={pausedref}
          setDeleteConfirmationModal={setDeleteConfirmationModal}
          />} key={route.path} />;
        }

        // Otherwise, show the "Not Allowed" component
        return <Route exact path={route.path} element={<>Not allowedd</>} key={route.path} />;
      }
      return null;
    });

  const mergeaudio = (x) => {
    const combinedAudioBlob = new Blob([retrieveAudioBlob(), x], { type: 'audio/webm' });
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result;
      sessionStorage.setItem('audioBlob', base64data);
    };
    reader.readAsDataURL(combinedAudioBlob);
  }
  if (location.pathname.includes('basic-archive-edit')) {
    console.log('Pathname contains basic-archive-edit');
  } else {
    console.log('Pathname does not contain basic-archive-edit');
  }
  //[background:linear-gradient(180deg,rgb(255,255,255)_20%,rgb(162,198,253)_100%)]

const style = location.pathname.includes('basic-archive-edit')|| location.pathname.includes('/archive-edit/')
  ? {
      background: 'linear-gradient(180deg, #FFF 34.38%, #A2C6FD 167.97%)',
    }
  : {}
  return (
    <div className='bg-white h-auto min-h-[100vh]  [background:linear-gradient(180deg,rgb(255,255,255)_20%,rgb(162,198,253)_100%)]'
  style={style}
    >
      {mydata.isOpen ? <AudioOverlay 
       stopRecording={stopRecording}
       pausedref={pausedref}
       setDeleteConfirmationModal={setDeleteConfirmationModal}
      Audiocollecter={Audiocollecter} mergeaudio={mergeaudio} /> : <></>}
      {mydata.isrecordingmodal ? <BasicRecordingWrap /> : <></>}
      
      {mydata.isStreamingModal&&mydata.isStreaming?<StreamingAudio   setopen={setOpen}
        setmessage={setMessage}
        setseverity={setSeverity} />:<></>}
      {currentRoute && currentRoute.showHeader && <MainHeader />}
      <ToastMessage
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <DeleteConfirmation savemodal={deleteconfirmationModal} setModalOpen={setDeleteConfirmationModal} onExit={deleteStream}/>
      <CombineToastModal isModalOpen={combineaudio} handlecombine={handlecombine} setModalOpen={setCombineAudio} />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isloading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Routes>

        {getRoutes(routes)}

        <Route path="/" element={<Navigate to="/auth1" />} />

        <Route path="*" element={<PageNotAvailable />} />
      </Routes>
    </div>
  );
}

export default App;
