import { useState } from "react";
import ProfileDetails from "./manageprofile";
import UpdatePassword from "pages/auth/screens/updatepassword";
import PasswordUpdate from "./passwordupdate";
import MobileNumberUpdate from "./mobilenumberupdate";
import EmailUpdate from "./emailupdate";
import UpdateCountry from "./updatecountry";
import ToastMessage from "utils/muialerts/righttoptoast";
import EhrUpdate from "./ehrupdate";


const ManageProf = () => {
    const [screen, setScreen] = useState(0);
    const[username,setUsername]=useState('')
    const [email,setEmail]=useState('')
    const[country,setCountry]=useState('')
    const [state,selectedState]=useState('')
    const[mobilenumber,setMobilenumber]=useState('')
    const [open, setOpen] = useState(false);
      const [alertmessage, setAlertMessage] = useState('');
      const [selectedEhr,SetSelectedEhr]=useState('')
      const [severity, setSeverity] = useState('success');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      }; 
    const screenhandler=(label,data)=>{
        setUsername(data.username)
        setMobilenumber(data.phone)
        setEmail(data.email)
        setCountry(data.country)
        selectedState(data.state)
        SetSelectedEhr(data.ehr)
if(label==='Current Password'){
    setScreen(1)
}else if(label==='Phone Number'){
    setScreen(2)
}else if(label==='Email'){
    setScreen(3)
}else if(label==='Country'||label==='State'){
    setScreen(4)
}else if(label==='EHR'){
    setScreen(5)
}

    }

    const renderScreen = () => {
        switch (screen) {
            case 0:
                return <ProfileDetails screenhandler={screenhandler}/>;
                case 1:
                    return <PasswordUpdate username={username}email={email} mobilenumber={mobilenumber} setScreen={setScreen} screenhandler={screenhandler}/>;
                 case 2:
                return <MobileNumberUpdate username={username} mobilenumber={mobilenumber}  email={email} setScreen={setScreen} screenhandler={screenhandler}/>;
                case 3:
                    return <EmailUpdate username={username} email={email} setScreen={setScreen} mobilenumber={mobilenumber} screenhandler={screenhandler}/>;
                case 4:
                        return <UpdateCountry 
                        setAlertMessage={setAlertMessage}
                        setSeverity={setSeverity}
                        setOpen={setOpen}
                        country={country} state={state} username={username} email={email} setScreen={setScreen} />;    
                 case 5:
                            return <EhrUpdate
                            setAlertMessage={setAlertMessage}
                            setSeverity={setSeverity}
                            setOpen={setOpen}
                           username={username} email={email} setScreen={setScreen} 
                           selectedEhr={selectedEhr}/>;      

            default:
                return <ProfileDetails screenhandler={screenhandler} />;
        }
    };

    return (
        <>
            <div className="flex items-center justify-center min-h-[90vh] w-full">
                {renderScreen()}
            </div>
           <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
        </>
    );
};

export default ManageProf;
