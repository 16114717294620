import React, { useEffect, useState } from 'react';
import logo from '../../assets/blinkscribe 4.svg';
import { Backdrop, CircularProgress } from '@mui/material';
import api from 'api/api';
import { useNavigate, useParams } from 'react-router-dom';
import arrowleft from '../../assets/arrowleft.png'
import ForceLogoutPopup from 'pages/landing/overlayauth/forcelogout';
const AppOptions = ({setScreen,handleVerified,setPopupOpen}) => {
  // State to track the selected option
  const [selectedOption, setSelectedOption] = useState(0);
  const{flow}=useParams()
  const navigate=useNavigate()
  const [role,setRole]=useState('')
const [loading,setLoading]=useState(false)
const [error,setError]=useState('')
  // Handler to set selected option
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setError('')
   

  };
 
  const fetchUser = async () => {
    try {
      const response = await api.get('/auth/me');  
      // Strict validation
      if (response?.config?.user_type && flow === 'self') {
        setRole(response.config.user_type);
      } else {
        setRole(''); // Assign empty string if validation fails
      }
    } catch (error) {
      console.error('Error fetching user:', error); // Log the error for debugging
      setRole(''); // Default to empty in case of error
    }
  };
  

const handlesubmit=async()=>{
  setError('')
  if(selectedOption==1){
    try {
      setLoading(true)        
      const response= await api.put('/v1/users/user-cofig-data',{
        "user_type": "BUSINESS",
 
      })
      if(response.status===200){
        setScreen(2)
        if(flow=="self"){
          setPopupOpen(true)
        }
      }else{
        setError('something went wrong')
      }
    } catch (error) {
      console.log(error)
      setError('something went wrong')
    }finally{

setLoading(false)
if(flow=="self"){
  // navigate('/manage-profile', { state: { refresh: Date.now() } });
  return
}
handleVerified()
    }
  }else{
    setScreen(1)
  }
}
  // Box shadow style for the selected option
  const selectedShadow = "shadow-[0px_0px_8px_8px_rgba(133,_182,_255,_0.35)]";
useEffect(()=>{
fetchUser()
},[])
  return (
    <>
      {/* Overlay Background - Covers the entire screen */}
      <div className="fixed inset-0 bg-black bg-opacity-50 z-10 min-h-screen min-w-full"></div>

      {/* Overlay Card - Centered, updated styles */}
      <div className="flex flex-col items-center justify-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-[12.781px] 
      shadow-[0px_0px_8px_8px_rgba(0,_0,_0,_0.04)] p-6 px-12 min-w-[160px] h-full w-full   md:h-auto sm:h-auto overflow-auto sm:w-[80%] md:w-[70%] min-h-[20px] z-20">
        
       { flow==='self'?<div className="flex w-[90%] mt-2 items-start">
            <img
              onClick={() => {
               navigate(-1)
               }}
              src={arrowleft}
              alt="Back Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>:<></>}
        <div className="md:w-[70%] sm:w-[80%]">
     
          <div className="flex justify-center mb-2 md:mt-0 sm:mt-0">
            <img
              src={logo}
              alt="BlinkScribe Logo"
              className="h-20 sm:h-14 md:h-14 w-24 sm:w-auto md:w-auto"
            />
          </div>

          <h2 className="text-lg font-semibold mb-4">Choose your Experience</h2>

          {/* Cards Container - Row direction on medium and larger screens */}
          <div className="flex flex-col flex-wrap md:flex-row gap-4 w-full">
            {/* Medical Card */}
            <div
              onClick={() => handleOptionClick(0)}
              className={`flex-1  flex flex-col justify-between  p-6 overflow-auto bg-white rounded-[12.781px] shadow-[0px_0px_12px_8px_rgba(0,0,0,0.10)] text-start cursor-pointer h-[400px] ${selectedOption === 0 ? selectedShadow : ''}`}
            >
              <div>
                              <h3 className="text-sm sm:text-lg font-semibold text-center">blinkScribe for Medical</h3>
              <ul className="mt-4 text-sm sm:text-md text-gray-600 space-y-2 list-disc pl-6">
                <li>
                  <span className="text-[#000] font-semibold">Speed and Accuracy</span>
                  <div>Real-time or near-instant transcription for medical professionals.</div>
                </li>
                <li>
                  <span className="text-[#000] font-semibold">HIPAA Compliance</span>
                  <div>Essential for security and privacy in healthcare.</div>
                </li>
                <li>
                  <span className="text-[#000] font-semibold">Voice Recognition for Medical Terms</span>
                  <div>Specialized for complex medical language.</div>
                </li>
              </ul>
              </div>
           {role==='PLUS'||role==='PRO'||role==='BASIC'?<div className='w-full mt-2 mb-2 flex items-center justify-center'>
<div className='bg-[#B0CDFB] tracking-wide text-sm font-bold text-[#FFFFFF] px-6 py-1'>
ACTIVE
</div>
           </div>:<></>}
            </div>

            {/* Meetings Card */}
            <div
              onClick={() => handleOptionClick(1)}
              className={`flex-1 p-6 flex flex-col justify-between overflow-auto  bg-white rounded-[12.781px] shadow-[0px_0px_12px_8px_rgba(0,0,0,0.10)] text-start cursor-pointer h-[400px] ${selectedOption === 1 ? selectedShadow : ''}`}
            >
              <div>
              <h3 className="text-sm sm:text-lg text-center font-semibold">blinkScribe for Meetings</h3>
              <ul className="mt-4 text-sm sm:text-md text-gray-600 space-y-2 list-disc pl-6">
                <li>
                  <span className="text-[#000] font-semibold">Real-Time Transcription</span>
                  <div>For live conferences, meetings, and presentations.</div>
                </li>
                <li>
                  <span className="text-[#000] font-semibold">Multi-Speaker Identification</span>
                  <div>Helpful for panel discussions and large meetings.</div>
                </li>
                <li>
                  <span className="text-[#000] font-semibold">Searchable Transcripts</span>
                  <div>Making it easy to find key topics or speakers.</div>
                </li>
              </ul>
              </div>
              {role==='BUSINESS'?<div className='w-full mt-2 mb-2 flex items-center justify-center'>
<div className='bg-[#B0CDFB] tracking-wide text-sm font-bold text-[#FFFFFF] px-6 py-1'>
ACTIVE
</div>
           </div>:<></>}
            </div>
          </div>

          {/* Additional Info */}
          <p className="text-black text-[14px] font-poppins italic font-light leading-normal tracking-[0.15px] mt-4">
            The experience can be modified in your profile settings after logging in.
          </p>
{error?<p className='text-red-600 text-sm'>{error}</p>:<></>}
          {/* Continue Button */}
          <div className="flex items-center justify-center w-full mt-6">
          <button
  onClick={() => handlesubmit()}
  disabled={loading} // Disable button when loading is true
  className={`flex w-[100%] sm:w-[45%] md:w-[35%] h-12 md:h-10 sm:h-8 items-center justify-center gap-2.5 px-6 py-4 rounded-full border border-neutral-500 transition duration-300 ease-in-out ${
    loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-black text-white'
  }`}
>
  <div className="font-semibold text-sm">
    {loading ? 'Submitting...' : 'Continue'}
  </div>
</button>

          </div>
        </div>
      </div>

   
    </>
  );
};

export default AppOptions;
