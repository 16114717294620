import profilering from '../assets/profilering.png';
import face from '../assets/profile.png';
import CloseIcon from '@mui/icons-material/Close';
import redcircle from '../assets/redcircle.png';
import pause from '../assets/Pause.png';
import play from '../assets/play.png';
import stop from '../assets/ion_stop.png';
import { useEffect, useRef, useState } from 'react';

import useModalStore from 'zustand/zustandstore';

import ReactiveWaves from 'audiopopup/reactivewaves';

const StreamModule=({ isPaused,formatTime,  setDeleteConfirmationModal,pausemanualRecording,isError,elapsedTime,togglePause,audioStreamRef,pausedref,stopTimer,setExitModal,setIsPaused,patientName})=>{
        const mydata=useModalStore()
             

    return(<>
    <div className="absolute left-0 right-0 top-[70px] mt-4 w-full flex justify-center md:top-0 md:left-[30%] md:right-0 md:mt-4 lg:w-[70%] xl:w-[50%]" style={{ zIndex: 99999999 }}>
            <div style={{ borderRadius: "4px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }} className="bg-white rounded-[15px] p-4 h-[53px] flex items-center justify-between w-full">
                {/* Profile icon section */}
                <div className='flex items-center gap-4'>
                    <div className="relative">
                        <img src={profilering} alt="Profile Ring" className="w-[44px] h-[45px]" />
                        <img src={face} alt="Face" className="absolute inset-0 w-[31px] h-[43px] m-auto rounded-full" />
                    </div>
                    <div className='font-semibold text-black text-sm'>
                    {patientName}
                    </div>
                </div>

                {/* Audio section */}
                <div className='flex gap-4 items-center'>
                <div
              className="relative cursor-pointer"
              onClick={() => {
                togglePause()
               }}
              
            >
              <img
                src={redcircle}
                alt="Red Circle"
                className="w-[35px] h-[35px] cursor-pointer"
              />
              <img
                src={!isPaused ? pause : play}
                alt="Pause/Play"
                className="absolute inset-0 m-auto w-[20px] h-[20px]"
              />
            </div>

                    <div className='flex flex-col items-center h-[90%] justify-between '>
                      
                      <div className='h-4 overflow-hidden'> 
                        <ReactiveWaves isPaused={!isPaused}  mediaStream={audioStreamRef.current}/>
                        </div>
                        <div
                        style={{fontStyle:"normal",fontFamily:'inter'}}
                        className='bg-#1C2A3A h-3 '>
                            {/* {recordingTime.length !== 5 ? "00:00" : (showsavedtime ? persistRecordTime : recordingTime)} */}
                            {formatTime(elapsedTime)}
                        </div>
                    </div>

                    <div className="relative cursor-pointer" 
                     onClick={()=> {
                      if(!isPaused){
                       stopTimer()
                       pausedref.current=false
                       setIsPaused(true)
                       if(isError){
                        pausemanualRecording()
                       }
                       
                      }
                      setExitModal(true)
             
                             //   pauseRecording();
                                                           
                               }}
                    >
                        <img src={redcircle} alt="Red Circle" className="w-[35px] h-[35px] cursor-pointer" />
                        <img src={stop} alt="Stop" className="absolute inset-0 m-auto w-[20px] h-[20px]" />
                    </div>
                </div>

                <div onClick={()=> {
         if(!isPaused){
          stopTimer()
          pausedref.current=false
          setIsPaused(true)
          if(isError){
            pausemanualRecording()
           }
         }
         setDeleteConfirmationModal(true)

                //   pauseRecording();
                                              
                  }} >
                    <CloseIcon style={{ color: "#7B7575" }} />
                </div>
            </div>
        </div>
     
    </>
   
  )
}
export default StreamModule;