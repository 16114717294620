import React, { useEffect, useState } from "react";
import { Backdrop, Card, CardContent, CircularProgress } from "@mui/material";
import blinkscribelogo from "../../../assets/blinkscribe.png";
import Select from "react-select";
import Flag from "react-world-flags";
import { useNavigate } from "react-router-dom";
import { Country, State } from "country-state-city";
import user from '../../../assets/user.png'
import sms from '../../../assets/sms.png'
import arrowleft from '../../../assets/arrowleft.png'
import api from "api/api";
import ToastMessage from "utils/muialerts/righttoptoast";
import PlanSelection from "../plans/planselection";
import zIndex from "@mui/material/styles/zIndex";
import ForceLogModal from "pages/plans/modal/forcelog";

const countries = [
  { code: "US", prefix: "+1", label: "United States", value: "US", minLength: 10 },
  { code: "IN", prefix: "+91", label: "India", value: "IN", minLength: 10 },
  { code: "GB", prefix: "+44", label: "United Kingdom", value: "GB", minLength: 10 },
  { code: "DE", prefix: "+49", label: "Germany", value: "DE", minLength: 11 },
  { code: "FR", prefix: "+33", label: "France", value: "FR", minLength: 9 },
];

const BlinkScribeSignup = () => {
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((country) => country.value === "US")
  );
    const[ismodal,setIsModal]=useState(false)
  const customStyle = {
  
  
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px',
    }),
  
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
  
    indicatorSeparator: state => ({
      display: 'none',
    }),
  
   
  
    menu: (provided) => ({
      ...provided,
      maxHeight: '100px', // Set fixed height for the dropdown
      overflowY: 'hidden',  // Ensure the dropdown is scrollable
      overflowX: 'hidden', // Hide horizontal scroll if any
    }),
  
    menuList: (provided) => ({
      ...provided,
      padding: 0, // Remove extra padding to avoid double scroll
    }),
  };
  const [selectedCountrylabel, setSelectedCountrylabel] = useState({value: "US",
    label: "United States",
  }

  );
const [selectedState, setSelectedState] = useState({value: "TX",
  label: "Texas",
});
const countryOptions = Country.getAllCountries().map((country) => ({
  value: country.isoCode,
  label: country.name,
}));

const [stateOptions, setStateOptions] = useState([]);
const [selectedehr,setSelectedeEhr]=useState('')
const [otherehr,setOtherEhr]=useState('')
useEffect(() => {
  if (selectedCountrylabel) {
   
    const states = State.getStatesOfCountry(selectedCountrylabel.value);
   
    setStateOptions(
      states.map((state) => ({
        value: state.isoCode,
        label: state.name,
      }))
    );
  } else {
    setStateOptions([]);
  }
}, [selectedCountrylabel]);


  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [errorMessage, setErrorMessage] = useState(""); // Use a string for error message
  const navigate = useNavigate();
    const [otp, setOtp] = useState(new Array(4).fill(""));
  const[isLoading,setIsLoading]=useState(false)
  const[selectedplan,setSelectedplan]=useState('')
  const[screen,setScreen]=useState(0)
  const[otperror,setOtpError]=useState('')
   const [open, setOpen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [finalcallload,setFinalcall]=useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      fontSize: "8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
  };

  const formatOptionLabel = ({ code, label }) => (
    <div className="flex items-center">
      <Flag
        code={code}
        className="mr-2"
        style={{ width: "20px", height: "15px" }}
      />
      <span>{label}</span>
    </div>
  );


  const handleKeyDown = (element, index) => {
    if (element.key === "Backspace") {
      if (otp[index] === "") {
        if (index > 0) {
          const updatedOtp = [...otp];
          updatedOtp[index - 1] = ""; // Clear the previous field
          setOtp(updatedOtp);
          element.target.previousSibling?.focus(); // Optionally focus the previous input field
        }
      } else {
        const updatedOtp = [...otp];
        updatedOtp[index] = ""; // Clear the current field
        setOtp(updatedOtp);
      }
    }
  };
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Move to next input if a number is entered
    if (element.nextSibling && element.value !== "") {
      element.nextSibling.focus();
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
const handleverifyotp= async()=>{
  const { firstName, lastName, email, phone } = formData;
setIsLoading(true)
try {
  const response = await api.post('/v1/auth/check-otp/user_signup',{
    "mobilephonenumber":  String(phone),
    "country_code":selectedCountry.prefix,
    "otp": otp.join('')
  })
if(response.status==200){
  // setSeverity('sucess')
  //    setAlertMessage('OTP Verified Successfully')
  //    setOpen(true)
  const usercreation= await api.post('/v1/subscribe/signup',{
    "countrycode": selectedCountry.prefix,
    "phonenumber": phone,
    "firstname": firstName,
    "lastname": lastName,
    "email": email,
    "config": {
      "providerid": 0,
      "practiceid": 0,
      "departmentid": "1",
      "ehrid": 0,
      "appointmenttypeids": [],
      'user_type':"SIGNUP",
      "country": selectedCountrylabel.label,
    "state": selectedState.label,
    "consent_required": false,
    "current_ehr": selectedehr==='Other'?otherehr:selectedehr
    },
    "otp":otp.join('')
  })

  if (response.status === 200) {
  
    setSeverity('success');
    setAlertMessage('You have successfully registered!');
    setOpen(true);
      setOtp(new Array(4).fill(""))
    const { access_token, data, id } =usercreation;
    sessionStorage.setItem('auth', access_token);
    localStorage.setItem('user', data[0]?.username);
    localStorage.setItem('bs_id', id);
    localStorage.setItem('dp_id', data[0]?.config?.departmentid);
    setScreen(2)
  }
  else if(response.status===201){
    setSeverity('info')
    setAlertMessage('mobile already registered!')
    setOpen(true)
  }else{
    setSeverity('error')
    setAlertMessage('Incorrect OTP')
    setOpen(true)
  }


}else{
  setSeverity('error')
     setAlertMessage('OTP expired or does not exist')
     setOpen(true)

}
  
} catch (error) {
  setSeverity('error')
  setAlertMessage('Something went wrong')
  setOpen(true)
}finally{
setIsLoading(false)
}



}



const handlesubmit= async()=>{
  const { firstName, lastName, email, phone } = formData;
  let errors = "";

  if (!firstName) {
    errors = "First name is required.";
  } else if (!lastName) {
    errors = "Last name is required.";
  } else if (!email) {
    errors = "Email is required.";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    errors = "Please enter a valid email address.";
  } else if (!phone) {
    errors = "Phone number is required.";
  } else if (!/^[0-9]+$/.test(phone)) {
    errors = "Phone number must contain only digits.";
  } else if (phone.length < selectedCountry.minLength) {
    errors = `Phone number should be at least ${selectedCountry.minLength} digits long.`;
  }

  if (errors) {
    setErrorMessage(errors); // Set the error message string to state
    return;
  }
  try {
    setIsLoading(true)
      const response=await api.post('/v1/auth/otp/user_signup',{
        "mobilephonenumber": phone,
        "country_code": selectedCountry.prefix,
        "email":email,
      })
      if(response.status==202){
        setSeverity('info')
        setErrorMessage(response.message)
      }else if(response.status==200){
        setErrorMessage('')
        setSeverity('success')
       setAlertMessage('OTP Sent Successfully')
        setOpen(true)
    setScreen(1)
    
      }
    } catch (error) {
      console.log(error)
    }finally{
    setIsLoading(false)
    
    
    }
  //setScreen(2)
}
const encodeData = (data) => {
  let encodedData = btoa(data);

  // Remove padding ('='), as Stripe does not support it
  encodedData = encodedData.replace(/=+$/, "");
  // Ensure it contains only alphanumeric, dashes, and underscores
  return encodedData.replace(/[^A-Za-z0-9_-]/g, "");
};
const finalcall=async(plan)=>{
  if(plan==='PLUS'){
    const bsId = localStorage.getItem('bs_id');
    const clientReferenceId = encodeData(`${bsId}|${'SIGNUP'}`);
    window.location.href =`https://buy.stripe.com/8wM6prcs38D35rO6oo?client_reference_id=${clientReferenceId}`;
return
  }else{

 const response= await api.put('/v1/users/user-cofig-data',{
        "user_type": plan,
 
      })
setIsModal(true)



  }
}
  return (
  <>
  {screen===2?<>  
  <PlanSelection 
    setScreen={setScreen} setSelectedplan={setSelectedplan}
    handleSignup={finalcall}/>
  </>:
    <div className="flex justify-center items-center h-auto min-h-[100vh]">
    <Card className={`w-[100%] md:w-[40%] sm:w-[40%] max-w-lg md:max-w-2xl p-6 shadow-lg h-screen ${screen === 0 ? 'md:min-h-[700px] h-auto' : 'md:h-[400px]'} 
    ${screen === 0 ? 'sm:h-[600px]' : 'sm:h-[400px]'}  rounded-lg bg-white md:bg-white`}>

        <CardContent className="h-full">
          <div className="flex items-start ">
            <img
              onClick={() => {
                navigate("/auth1");
              }}
              src={arrowleft}
              alt="Down Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <div className="flex flex-col items-center justify-between h-full">
            <div className="flex justify-center">
              <img
                src={blinkscribelogo}
                alt="BlinkScribe Logo"
                className="h-16 w-auto"
              />
            </div>
            {/* heading */}
            {screen===0?<div>
              <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#1c2a3a] text-xl tracking-[0] leading-9 whitespace-nowrap">
                Create Account
              </div>
              <div className="[font-family:'Inter-Regular',Helvetica] font-normal text-gray-500 text-[13px] tracking-[0] leading-[22.5px] whitespace-nowrap">
                We are here to help you!
              </div>
            </div>:<>
            <div>
              <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#1c2a3a] text-xl tracking-[0] leading-9 whitespace-nowrap">
              One Time Passcode
              </div>
              <div className="[font-family:'Inter-Regular',Helvetica] font-normal text-gray-500 text-[13px] tracking-[0] leading-[22.5px] whitespace-nowrap">
              Enter the code sent to your phone number.
              </div>
            </div>
            </>}
            
          {screen===0?<>
           <div className="w-full flex flex-col items-center gap-4 mb-4">
              <div className="w-[100%] md:w-[60%] sm:w-[60%]">
                <input
                  name="firstName"
                  type="text"
                  placeholder="Enter First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                />
              </div>
              <div className="w-[100%] md:w-[60%] sm:w-[60%]">
                <input
                  name="lastName"
                  type="text"
                  placeholder="Enter Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                />
              </div>
              <div className="w-[100%] md:w-[60%] sm:w-[60%]">
                <input
                  name="email"
                  type="text"
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal border border-gray-300"
                />
              </div>
             
              <div
                style={{ border: "1px solid #dcdcdc" }}
                className="flex flex-row w-[100%] md:w-[60%] sm:w-[60%] rounded-lg p-1 gap-2"
              >
                <div style={{ width: "80px" }} className="flex items-center">
                  <Select
                    value={selectedCountry}
                    onChange={setSelectedCountry}
                    options={countries}
                    styles={customStyles}
                    formatOptionLabel={formatOptionLabel}
                  />
                </div>
                <div className="flex-1">
                  <input
                    name="phone"
                    type="tel"
                    placeholder="Enter Phone Number"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="w-full bg-transparent h-[42px] md:h-[32px] sm:h-[32px] p-3 rounded-lg outline-none text-gray-800 text-sm font-normal"
                    pattern="[0-9]*"
                    inputMode="numeric"
                  />
                </div>
              </div>
              <div className="flex flex-row w-[100%] md:w-[60%] sm:w-[60%] gap-2  ">
              <div className="flex-1">
          <Select
              className="text-sm text-start"
            value={selectedCountrylabel}
            onChange={setSelectedCountrylabel}
            options={countryOptions}
            placeholder="Select Country"
            styles={customStyle}
          />
        </div>
        {selectedCountrylabel.value==="US"?<div className="flex-1">
          <Select
          className="text-sm text-start custom-dropdown"
            value={selectedState}
            onChange={setSelectedState}
            options={stateOptions}
            placeholder="Select State"
            isDisabled={!selectedCountry} // Disable if no country is selected
            styles={customStyle}
            
                    />
        </div>:<></>}
       
      </div>
      <div className="w-full md:w-3/5 sm:w-3/5 flex items-center space-x-2">
      <select
        className="block w-full text-sm   text-left p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={selectedehr}
        onChange={(e) => setSelectedeEhr(e.target.value)}
      >
    <option value="" disabled>Select EHR (optional)</option>
    <option value="Athena">Athena</option>
    <option value="Epic">Epic</option>
    <option value="Cerner">Cerner</option>
    <option value="Other">Other</option>
  </select>
  
</div>
<div className="w-[100%] md:w-[60%] sm:w-[60%]" >
{selectedehr === 'Other' && (
        <input
          type="text"
          className="p-2 border w-full border-gray-300 rounded-md text-sm"
          placeholder="Enter your EHR here"
          value={otherehr}
          onChange={(e) => setOtherEhr(e.target.value)}
        />
      )}
</div>

            </div>
            {errorMessage && (
              <div className="text-red-500 text-sm font-medium w-[100%] md:w-[60%] sm:w-[60%] text-center">
                {errorMessage}
              </div>
            )}
            <div className="w-full flex items-center flex-col gap-4">
              <button
                onClick={()=>{handlesubmit()}}
                className={`flex w-[100%] md:w-[60%] sm:w-[60%] h-15 md:h-10 sm:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out`}
              >
                <div className="font-semibold text-base">Create Account</div>
              </button>
              <p className="[font-family:'Inter-Regular',Helvetica] font-normal text-transparent text-[15px] text-center tracking-[0] leading-[22.5px]">
                <span className="text-gray-500">Have an account yet?</span>
                <span className="text-gray-500 text-sm leading-[21px]">&nbsp;</span>
                <span
                  onClick={() => navigate('/auth1')}
                  style={{ cursor: "pointer" }}
                  className="[font-family:'Inter-Medium',Helvetica] font-medium text-[#1c64f2] text-sm leading-[21px]"
                >
                  Sign in
                </span>
              </p>
            </div></> :
<div className="flex flex-col gap-2 items-center">
<div className="flex justify-center gap-2 w-[90%] md:w-[70%] mt-4 sm:w-[70%]">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="w-12 h-12 md:w-10 md:h-10 bg-transparent p-3 rounded-lg outline-none border border-gray-300 text-center text-gray-800 text-xl font-normal"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            {otperror && (
              <div className="text-red-500 text-sm font-medium w-[100%] md:w-[60%] sm:w-[60%] text-center">
                {otperror}
              </div>
            )}
            <div className="flex  mt-2 flex-col items-center space-y-4 w-full mb-24 md:mb-0 sm:mb-0">
              {/* Verify Button */}
              <button
               onClick={()=>handleverifyotp()}
                className="flex w-[90%] md:w-[70%] sm:w-[70%] h-15 sm:h-10 md:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out"
              >
                <div className="font-semibold text-base">Verify</div>
              </button>
            </div>
            </div>}

            <div>
              {/* <div
                className="font-semibold text-base text-center cursor-pointer"
                style={{ color: "#0F172A", fontSize: "15px", fontWeight: "500", lineHeight: "150%" }}
              >
                Need Help?
              </div> */}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
}
<Backdrop
  sx={{
    zIndex: (theme) => theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.9)", // Semi-transparent background
  }}
  open={finalcallload}
>
  <div
    className="text-white font-mono text-xl font-bold overflow-hidden whitespace-nowrap border-r-2 border-white"
    style={{
      maxWidth: "28ch", // Match the length of "Generating Prompt .."
      animation: "typing 2s steps(18, end) infinite, blink 0.5s step-end infinite",
    }}
  >
    Registering User ..
  </div>
  <style>
    {`
      @keyframes typing {
        from {
          width: 0;
        }
        to {
          width: 28ch; /* Matches the length of the text */
        }
      }
      @keyframes blink {
        from, to {
          border-color: transparent;
        }
        50% {
          border-color: white;
        }
      }
    `}
  </style>
</Backdrop>
<ForceLogModal isModalOpen={ismodal} selectedplan={selectedplan} />

    <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BlinkScribeSignup;
