import React, { useEffect, useState } from 'react';
import profilering from '../../assets/profilering.png';
import face from '../../assets/profile.png';
import { useNavigate } from 'react-router-dom';
import arrowleft from '../../assets/arrowleft.png';
import { Snackbar } from '@mui/material';
import Select from 'react-select';
import { Country, State } from 'country-state-city';
import api from 'api/api';

const UpdateCountry = ({ setScreen,setAlertMessage,setSeverity,setOpen, username, email, country, state }) => {

    
    const countryOptions = Country.getAllCountries().map(c => ({
        value: c.isoCode,
        label: c.name,
    }));

    const defaultCountry = countryOptions.find(c => c.label === country) || { value: 'US', label: 'United States' };
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
    
    const [stateOptions, setStateOptions] = useState([]);
    
    useEffect(() => {
        if (selectedCountry) {
            const states = State.getStatesOfCountry(selectedCountry.value);
            setStateOptions(states.map(s => ({ value: s.isoCode, label: s.name })));
        } else {
            setStateOptions([]);
        }
    }, [selectedCountry]);

    const defaultState = stateOptions.find(s => s.label === state) || { value: 'TX', label: 'Texas' };
    const [selectedState, setSelectedState] = useState(defaultState);
    
    useEffect(() => {
        if (stateOptions.length > 0) {
            setSelectedState(stateOptions.find(s => s.label === state) || { value: 'TX', label: 'Texas' });
        }
    }, [stateOptions, state]);

    const handleSubmit = async () => {
        try {
            const payload = {
                country: selectedCountry?.label
            };
    
            if (true) {
                payload.state = selectedState?.label;
            }
    
            const response = await api.put('/v1/users/user-cofig-data', payload);
            if (response.status === 200) {
                setAlertMessage('Data updated successfully!')
              setSeverity('success')
              setOpen(true)
            } else {
                setAlertMessage('Error updating data:')
            setSeverity('error')
            setOpen(true)
            }
        } catch (error) {
            console.error("Error updating user config data:", error);
            setAlertMessage('Error updating user config data:')
            setSeverity('error')
            setOpen(true)
        } finally {
          
            setTimeout(() => {
                setScreen(0);      
            }, 1500);
        }
    };
    

   

    return (
        <div className="w-full bg-white sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-lg md:max-w-2xl p-6 shadow-lg rounded-lg h-screen sm:h-[400px] md:h-[400px]">
            <div className='flex gap-8'>
                <img onClick={() => setScreen(0)} src={arrowleft} alt="Back Arrow" className="w-5 h-5 cursor-pointer" />
                <div className="flex items-center gap-2">
                    <div className="relative">
                        <img src={profilering} alt="Profile Ring" className="w-[75px] h-[75px]" />
                        <img src={face} alt="Face" className="absolute w-[60px] h-[61px] left-2 top-3 rounded-full" />
                    </div>
                    <div className='flex flex-col text-start'>
                        <div className="font-semibold text-black text-lg">{username}</div>
                        <div className="opacity-50 text-black text-sm">{email || ''}</div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-around h-[80%]'>
                <label className="font-semibold text-black">Update Country & State</label>
                <div className='w-full flex flex-col items-center gap-6 mt-4'>
                    <div className='w-full sm:w-[40%]'>
                        <Select value={selectedCountry} onChange={setSelectedCountry} options={countryOptions} placeholder="Select Country" />
                    </div>
                    {selectedCountry.value === "US" && (
                        <div className='w-full sm:w-[40%]'>
                            <Select value={selectedState} onChange={setSelectedState} options={stateOptions} placeholder="Select State" isDisabled={stateOptions.length === 0} />
                        </div>
                    )}
                </div>
                <div className='w-full flex justify-center mt-6'>
                    <button onClick={() => setScreen(0)} className="rounded-full w-[120px] bg-black text-white py-2 px-4 hover:bg-gray-800">Cancel</button>
                    <button onClick={handleSubmit} className="rounded-full w-[120px] bg-black text-white py-2 px-4 hover:bg-gray-800 ml-4">Save</button>
                </div>
            </div>
            
        </div>
    );
};

export default UpdateCountry;
