import EditScreenWaves from "pages/recording/archivemusicwaves/editscreenwave/editscreenwave"
import redcircle from "../../assets/redcircle.png";
import pause from "../../assets/Pause.png";
import play from "../../assets/play.png";
import { useEffect, useRef, useState } from "react";

const ArcheiveStreaming=({stopRecording,onDelete,setIsexitModal,setIsPaused,isPaused,stopTimer,audioStreamRef,elapsedTime,formatTime,pausedref,togglePause})=>{
 


  
    return(<>
     <div className="flex flex-col items-center justify-center">
      <div className="flex items-center justify-center gap-8 mb-3">
<div >
<button
              onClick={() => {
                if(!pausedref.current){
                  stopTimer()
                  setIsPaused(true)
                  pausedref.current=false              
                 
                 }
                onDelete()
              }}
              className="bg-[#1c2a3ae6] rounded-lg p-2 "
            >
              <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-xs text-center tracking-[0] leading-[normal] whitespace-nowrap">
                Delete
              </div>
            </button>
</div>


        { !isPaused? (
        <div
        className="relative cursor-pointer"
        onClick={() => {
            togglePause()
           }}
      >
        <img
          src={redcircle}
          alt="Red Circle"
          className="w-[50px] h-[50px] cursor-pointer"
        />
        <img
          src={pause}
          alt="Pause/Play"
          className="absolute inset-0 m-auto w-[24px] h-[24px]"
        />
      </div>
        ) :
          <div
          className="relative cursor-pointer"
          onClick={() => {
            togglePause()
           }}
        >
          <img
            src={redcircle}
            alt="Red Circle"
            className="w-[50px] h-[50px] cursor-pointer"
          />
          <img
            src={play}
            alt="Pause/Play"
            className="absolute inset-0 m-auto w-[24px] h-[24px]"
          />
        </div>
        }

        <button
             onClick={()=> {
                if(!pausedref.current){
                 stopTimer()
                 setIsPaused(true)
                 pausedref.current=false              
                
                }
                setIsexitModal(true)
                                                                       
                        }}
              className="bg-[#1c2a3ae6] cursor-pointer rounded-lg p-2"
            >
              <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-white text-xs text-center tracking-[0] leading-[normal] whitespace-nowrap">
                Save
              </div>
            </button>

    </div>
    <div className="h-8 overflow-hidden">
     <EditScreenWaves  mediaStream={audioStreamRef.current} isPaused={isPaused} />
      </div>
      <p className="ml-2 text-sm"> {formatTime(elapsedTime)}
      </p>
  
    </div>
    
    </>)
}
export default ArcheiveStreaming