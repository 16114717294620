import { useState, useEffect, useRef } from "react";
import HomeIcon from '../assets/svgs/homeicon';
import logo from '../assets/blinkscribe.png';
import ProfileIcon from '../assets/svgs/profileicon';
import { useNavigate } from "react-router-dom";
import { clearPersistedData, resetData, resetSettings, setProviders, setVisittype } from "../redux/store";
import { useDispatch } from "react-redux";
import useModalStore from "zustand/zustandstore";
import ToastMessage from "utils/muialerts/righttoptoast";
import { FormControlLabel, Switch } from "@mui/material";

const MainHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { isOpen, openModal, closeModal,setModalValue } = useModalStore();
    const menuRef = useRef(null);
    const navigate = useNavigate();
    const dispatch= useDispatch()
    const mydata = useModalStore();
    const [open, setOpen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    }; 
    const handleProfileClick = () => {
      
        setIsMenuOpen(!isMenuOpen);
        navigate('/manage-profile');
    };

    // Close the menu if clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false); // Close menu
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className="relative flex flex-row justify-between items-center w-full p-2 sm:px-10">
            <div className="flex items-end gap-3"
             onClick={()=>{
                //  if(mydata.resrictNavgations){
              
                //   setModalValue({ triggerHome:true })
                //     return
                //     }
                //     if(mydata.usertype==='PRO'){
                //       navigate('/landing')
                //    }else{
                //      navigate('/app') 
                //    }
                if (mydata.resrictNavgations) {
                  setModalValue({ triggerHome: true });
                  return;
                }
            
                const currentPath = window.location.pathname;
            
                if (mydata.usertype === 'PRO') {
                  if (currentPath !== '/landing') {
                    navigate('/landing');
                  } else {
                        clearPersistedData()
                    window.location.reload();
                  }
                } else {
                  if (currentPath !== '/app') {
                    navigate('/app');
                  } else {
                        clearPersistedData();
                    window.location.reload();
                  }
                }
            }


            }>
                <img src={logo} alt="BlinkScribe Logo" className="h-8 sm:h-10 cursor-pointer" />
                {
  mydata.usertype === 'PRO' ? (
    <div className="font-extralight text-black text-sm sm:text-sm tracking-[0] leading-normal">
      PRO
    </div>
  ) : mydata.usertype === 'PLUS' ? (
    <div className="font-extralight text-black text-sm sm:text-sm tracking-[0] leading-normal">
      Plus
    </div>
  ) : mydata.usertype === 'BUSINESS' ? (
    <div className="font-extralight text-black text-sm sm:text-sm tracking-[0] leading-normal">
      Business
    </div>
  ) : (
    <div className="font-extralight text-black text-sm sm:text-sm tracking-[0] leading-normal">
      Basic
    </div>
  )
}
<button
  className="ml-4 px-3 py-1 text-xs font-medium text-white bg-[#e08474] rounded hover:bg-[#c96d60] hover:scale-105 hover:shadow-lg transition-all duration-200"
  onClick={() => window.location.href = 'https://blinkscribe.com/blinkscribelegacy'}
>
  Legacy Login
</button>



            </div>
            <div className="flex items-center gap-3 sm:gap-5 relative">
{/*            
            <FormControlLabel
  control={
    <Switch
      checked={mydata.isStreaming}
      onChange={() => {
        if(mydata. isStreamingModal){
return
        }
        setModalValue({ isStreaming: !mydata.isStreaming })}}
      color="primary"
    />
  }
    labelPlacement="start"
  label="Streaming"
  className="text-sm font-medium"
  sx={{
    marginTop: 0,
    marginBottom: 0,  // Remove extra top/bottom margin
    paddingTop: 0,
    paddingBottom: 0, // Remove extra padding
  }}
/> */}


           
            <div 
  style={{cursor: 'pointer'}} 
  onClick={() => {
    if (mydata.resrictNavgations) {
      setModalValue({ triggerHome: true });
      return;
    }

    const currentPath = window.location.pathname;

    if (mydata.usertype === 'PRO') {
      if (currentPath !== '/landing') {
        navigate('/landing');
      } else {
            clearPersistedData()
        window.location.reload();
      }
    } else {
      if (currentPath !== '/app') {
        navigate('/app');
      } else {
            clearPersistedData();
        window.location.reload();
      }
    }
  }}
>
  <HomeIcon className="h-6 sm:h-8 cursor-pointer" />
</div>

                <div className="relative">
                    <div style={{cursor:'pointer'}} onClick={()=>{
                       if(mydata.resrictNavgations){
                        setAlertMessage('You are not allowed to navigate while recording is in progress!');
           setSeverity('info'); // Change severity as needed
           setOpen(true);
         return
         }
                      toggleMenu()}}>
                        <ProfileIcon className="h-6 sm:h-8 cursor-pointer" />
                    </div>

                    {isMenuOpen && (
  <div
    ref={menuRef}
    className="absolute right-0 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg z-10 w-36 max-w-auto"
  >
    <ul className="py-2">
      <li
        onClick={handleProfileClick}
        className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
      >
        My Profile
      </li>
      <li
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
          navigate('/lock');
        }}
        className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
      >
        Lock
      </li>
      <li
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
          dispatch(resetSettings())
          dispatch(setProviders([]))
          dispatch(setVisittype([]))
          dispatch(resetData());
          localStorage.removeItem('mytab')
          sessionStorage.removeItem('auth');
          navigate('/auth1');
        }}
        className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
      >
        Logout
      </li>
    </ul>
  </div>
)}

                </div>
            </div>

            <ToastMessage message={alertmessage} severity={severity} open={open} handleClose={handleClose} />
        </div>
    );
};

export default MainHeader;
