import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
const StreamingCombineToastModal = ({isModalOpen,setModalOpen,handlecombine}) => {

 if(!isModalOpen){
    return
 }
 
  return (
    <>
      {isModalOpen && (
        <div
          id="popup-modal"
          tabIndex="-1"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-lg max-h-full">
            <div className="relative bg-white rounded-lg shadow ">
              {/* <button
                type="button"
                className="absolute top-2 right-2  bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-md w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={()=>{                  
                  setModalOpen(false)                
                }}
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button> */}
              <div className="p-4 md:p-5  text-center">
                <div>
           <InfoIcon/>
                </div>
             
                <div className="mb-2 mt-3  font-sans text-lg font-[400]">
                Recent record found. Do you wish to combine it or create a new one?
                </div>
               
                <div className="flex items-center justify-center mt-4 gap-5 w-full">
                  <button 
                  onClick={()=>handlecombine('combine')}
                    className="w-max p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center">
               Combine
                  </button>
                  <button
                  onClick={()=>handlecombine('new')}
                    className="w-max p-4 h-10 bg-[#1c2a3a] text-white text-sm font-semibold rounded-[14px] flex items-center justify-center">
                 Create New
                   </button>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
 
export default StreamingCombineToastModal;