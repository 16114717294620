import { openDB } from 'idb';

export const saveAudioInIndexDB = async ({
  file, practiceid, departmentid, patientid, notes, tags, 
  patientname, templatename, role, category, rowid, filename,appointmentid,
}) => {
  try {
    // Open or create IndexedDB database
    const db = await openDB('audioDB', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('audioStore')) {
          db.createObjectStore('audioStore', { keyPath: 'practiceid' }); 
        }
      },
    });

    // Save data
    await db.put('audioStore', {
      rowid, // Unique identifier
      file, // Audio Blob
      practiceid,
      departmentid,
      patientid,
      notes,
      tags,
      patientname,
      templatename,
      role,
      category,
      filename,
      appointmentid,
      createdAt: new Date().toISOString(), // Store timestamp
    });

    
    console.log('Audio file saved successfully!');
  } catch (error) {
    console.error('Error saving audio:', error);
  }
};

export const getAudioByPracticeId = async (practiceid) => {
    try {
      const db = await openDB('audioDB', 1);
      const tx = db.transaction('audioStore', 'readonly');
      const store = tx.objectStore('audioStore');
      const allRecords = await store.getAll(); // Get all records
  
      // Filter records by practiceid
      const filteredRecords = allRecords.filter(record => record.practiceid === practiceid);
      
      return filteredRecords;
    } catch (error) {
      console.error('Error retrieving audio by practice ID:', error);
      return [];
    }
  };
  export const getAllAudioRecords = async () => {
    try {
      const db = await openDB('audioDB', 1);
      const tx = db.transaction('audioStore', 'readonly');
      const store = tx.objectStore('audioStore');
      const allRecords = await store.getAll(); // Get all records
  
      return allRecords;
    } catch (error) {
      console.error('Error retrieving all audio records:', error);
      return [];
    }
  };
  

  